import { IErrorResponse } from "../../../api/ErrorResponse";
import { ICitofono } from "../../../api/Models";

export enum CitofoniActionTypes {
  CITOFONO_SHOW = 'CITOFONO_SHOW',
  CITOFONO_HIDE = 'CITOFONO_HIDE',
  CIT_UPD_REQUEST = 'CIT_UPD_REQUEST',
  CIT_UPD_SUCCESS = 'CIT_UPD_SUCCESS',
  CIT_UPD_FAILURE = 'CIT_UPD_FAILURE',
  CIT_APRI_REQUEST = 'CIT_APRI_REQUEST',
  CIT_APRI_SUCCESS = 'CIT_APRI_SUCCESS',
  CIT_APRI_FAILURE = 'CIT_APRI_FAILURE',
}

export interface CitofonoShow {
  type: CitofoniActionTypes.CITOFONO_SHOW;
  citofono: ICitofono;
}

export interface CitofonoHide {
  type: CitofoniActionTypes.CITOFONO_HIDE;
}

export interface CitofoniUpdRequest {
  type: CitofoniActionTypes.CIT_UPD_REQUEST;
  citofono: ICitofono;
}

export interface CitofoniUpdSuccess {
  type: CitofoniActionTypes.CIT_UPD_SUCCESS;
  citofono: ICitofono;
}

export interface CitofoniUpdFailure {
  type: CitofoniActionTypes.CIT_UPD_FAILURE;
  error: IErrorResponse;
}

export interface CitofoniApriRequest {
  type: CitofoniActionTypes.CIT_APRI_REQUEST;
  idCitofono: number;
}

export interface CitofoniApriSuccess {
  type: CitofoniActionTypes.CIT_APRI_SUCCESS;
  citofono: ICitofono;
}

export interface CitofoniApriFailure {
  type: CitofoniActionTypes.CIT_APRI_FAILURE;
  error: IErrorResponse;
}

export type CitofoniAction =
  | CitofonoShow
  | CitofonoHide
  | CitofoniUpdRequest
  | CitofoniUpdSuccess
  | CitofoniUpdFailure
  | CitofoniApriRequest
  | CitofoniApriSuccess
  | CitofoniApriFailure
  ;

// ---------------
// Action Creators
// ---------------

export const citofonoShow = (citofono: ICitofono): CitofonoShow => ({
  type: CitofoniActionTypes.CITOFONO_SHOW,
  citofono: citofono
});

export const citofonoHide = (): CitofonoHide => ({
  type: CitofoniActionTypes.CITOFONO_HIDE
});

export const citofoniUpdRequest = (citofono: ICitofono): CitofoniUpdRequest => ({
  type: CitofoniActionTypes.CIT_UPD_REQUEST,
  citofono: citofono
});

export const citofoniUpdSuccess = (citofono: ICitofono): CitofoniUpdSuccess => ({
  type: CitofoniActionTypes.CIT_UPD_SUCCESS,
  citofono: citofono
});

export const citofoniUpdFailure = (error: IErrorResponse): CitofoniUpdFailure => ({
  type: CitofoniActionTypes.CIT_UPD_FAILURE,
  error: error,
});

export const citofoniApriRequest = (idCitofono: number): CitofoniApriRequest => ({
  type: CitofoniActionTypes.CIT_APRI_REQUEST,
  idCitofono: idCitofono
});

export const citofoniApriSuccess = (citofono: ICitofono): CitofoniApriSuccess => ({
  type: CitofoniActionTypes.CIT_APRI_SUCCESS,
  citofono: citofono
});

export const citofoniApriFailure = (error: IErrorResponse): CitofoniApriFailure => ({
  type: CitofoniActionTypes.CIT_APRI_FAILURE,
  error: error,
});
