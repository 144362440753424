import { IErrorResponse } from "../../../api/ErrorResponse";
import { IHomeResponse } from "../../../api/Models";

export enum HomeActionTypes {
  HOME_DATA_REQUEST = 'HOME_DATA_REQUEST',
  HOME_DATA_SUCCESS = 'HOME_DATA_SUCCESS',
  HOME_DATA_FAILURE = 'HOME_DATA_FAILURE',
}

export interface HomeDataRequest {
  type: HomeActionTypes.HOME_DATA_REQUEST;
}

export interface HomeDataSuccess {
  type: HomeActionTypes.HOME_DATA_SUCCESS;
  homeData: IHomeResponse;
}

export interface HomeDataFailure {
  type: HomeActionTypes.HOME_DATA_FAILURE;
  error: IErrorResponse;
}

export type HomeAction =
  | HomeDataRequest
  | HomeDataSuccess
  | HomeDataFailure
  ;

// ---------------
// Action Creators
// ---------------

export const homeDataRequest = (): HomeDataRequest => ({
  type: HomeActionTypes.HOME_DATA_REQUEST
});

export const homeDataSuccess = (homeData: IHomeResponse): HomeDataSuccess => ({
  type: HomeActionTypes.HOME_DATA_SUCCESS,
  homeData: homeData
});

export const homeDataFailure = (error: IErrorResponse): HomeDataFailure => ({
  type: HomeActionTypes.HOME_DATA_FAILURE,
  error: error,
});