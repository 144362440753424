import React from 'react';
import { Dispatch } from "redux";
import { Redirect } from 'react-router'
import '../../App.css';
import { IHomeResponse, IUtente } from '../../api/Models';
import { IStackTokens, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { Action } from '../controller/login/Actions';
import { HomeAction } from '../controller/home/Actions';
import { AppState } from '../RootReducer';
import { connect } from 'react-redux';
import CitofonoElem from '../component/CitofonoElem';
import ClienteElem from '../component/ClienteElem';
import LogEventiList from '../component/LogEventiList';
import Header from './Header';

interface IHomeViewProps {
  user: IUtente | null;
  homeData: IHomeResponse | null;  
  errorMsg: string | null;
}

interface IHomeViewState {
}

class HomeView extends React.Component<IHomeViewProps, IHomeViewState> {
  constructor(props: IHomeViewProps) {
    super(props);
    this.state = {
    };

  }

  public render(): JSX.Element {
    if (!this.props.user) {
      console.log('Home --> user == null');
      return <Redirect to="/login" />;
    }
    const customSpacingStackTokens: IStackTokens = {
      childrenGap: '20px',
      padding: 'm 40px'
    };    
    return (
      <Stack horizontal={true} styles={{ root: { margin: '20px', flexGrow: 1, flexShrink: 1 } }}>
        <Stack styles={{ root: { flexGrow: 1, width: '100% !important' } }}>
          {(this.props.errorMsg && this.props.errorMsg.trim().length > 0) &&
          <MessageBar
            messageBarType={MessageBarType.error}
          >
            {this.props.errorMsg}
          </MessageBar>
          }
          <Header title='Gestione citofoni'/>
          <Stack horizontal={true} tokens={customSpacingStackTokens} wrap>
            {this.props.homeData?.citofoni && this.props.homeData?.citofoni.map((citofono) => {
            return <CitofonoElem key={citofono.idCitofono} citofono={citofono}/>
            })}
            {this.props.homeData?.clienti && this.props.homeData?.clienti.map((cli) => {
            return <ClienteElem key={cli.idCliente} citofoni={this.props.homeData?.citofoni} cliente={cli} />
            })}
          </Stack>
          {(this.props.homeData?.lastLogEvents && this.props.homeData?.lastLogEvents.length > 0) &&
            <LogEventiList eventi={this.props.homeData?.lastLogEvents} citofoni={this.props.homeData?.citofoni}/>
          }
        </Stack>
      </Stack>
    );
  }
}


const MapStateToProps = (store: AppState) => {
  return {
    user: store.auth?.user,
    homeData: store.home?.homeData,
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action | HomeAction>) => ({
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(HomeView);