import { IErrorResponse } from "../../../api/ErrorResponse";
import { IApriPortaResponse } from "../../../api/Models";

export enum ApriPortaActionTypes {
  APRIPORTA_DATA_REQUEST = 'APRIPORTA_DATA_REQUEST',
  APRIPORTA_DATA_SUCCESS = 'APRIPORTA_DATA_SUCCESS',
  APRIPORTA_DATA_FAILURE = 'APRIPORTA_DATA_FAILURE',
  APRIPORTA_APRI = 'APRIPORTA_APRI',
  APRIPORTA_APRI_OK = 'APRIPORTA_APRI_OK',
  APRIPORTA_APRI_ERR = 'APRIPORTA_APRI_ERR',
}

export interface ApriPortaDataRequest {
  type: ApriPortaActionTypes.APRIPORTA_DATA_REQUEST;
  uuid: string;
}

export interface ApriPortaDataSuccess {
  type: ApriPortaActionTypes.APRIPORTA_DATA_SUCCESS;
  apriResponse: IApriPortaResponse;
}

export interface ApriPortaDataFailure {
  type: ApriPortaActionTypes.APRIPORTA_DATA_FAILURE;
  error: IErrorResponse;
}


export interface ApriPortaApri {
  type: ApriPortaActionTypes.APRIPORTA_APRI;
  uuid: string;
}

export interface ApriPortaApriSuccess {
  type: ApriPortaActionTypes.APRIPORTA_APRI_OK;
  apriResponse: IApriPortaResponse;
}

export interface ApriPortaApriFailure {
  type: ApriPortaActionTypes.APRIPORTA_APRI_ERR;
  error: IErrorResponse;
}

export type ApriPortaAction =
  | ApriPortaDataRequest
  | ApriPortaDataSuccess
  | ApriPortaDataFailure
  | ApriPortaApri
  | ApriPortaApriSuccess
  | ApriPortaApriFailure
  ;

// ---------------
// Action Creators
// ---------------

export const apriPortaDataRequest = (uuid: string): ApriPortaDataRequest => ({
  type: ApriPortaActionTypes.APRIPORTA_DATA_REQUEST,
  uuid: uuid
});

export const apriPortaDataSuccess = (apriResponse: IApriPortaResponse): ApriPortaDataSuccess => ({
  type: ApriPortaActionTypes.APRIPORTA_DATA_SUCCESS,
  apriResponse: apriResponse
});

export const apriPortaDataFailure = (error: IErrorResponse): ApriPortaDataFailure => ({
  type: ApriPortaActionTypes.APRIPORTA_DATA_FAILURE,
  error: error,
});

export const apriPortaApri = (uuid: string): ApriPortaApri => ({
  type: ApriPortaActionTypes.APRIPORTA_APRI,
  uuid: uuid
});

export const apriPortaApriSuccess = (apriResponse: IApriPortaResponse): ApriPortaApriSuccess => ({
  type: ApriPortaActionTypes.APRIPORTA_APRI_OK,
  apriResponse: apriResponse
});

export const apriPortaApriFailure = (error: IErrorResponse): ApriPortaApriFailure => ({
  type: ApriPortaActionTypes.APRIPORTA_APRI_ERR,
  error: error,
});
