import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    ApriPortaActionTypes,
    apriPortaDataSuccess,
    ApriPortaDataRequest,
    apriPortaDataFailure,
    ApriPortaApri,
    apriPortaApriSuccess,
    apriPortaApriFailure
} from './Actions';
import { ICitofono } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';

export function* apriPortaDataRequestSaga(action: ApriPortaDataRequest) {
    try {
        console.log('saga apri porta data...');
        let ret: ICitofono = yield call([ApiClient.getInstance(), 'getApriPortaInfo'], action.uuid);
        yield put(apriPortaDataSuccess(ret));
        console.log('Apri porta data OK');
    } catch (error) {
        console.log('ApriPortaData ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(apriPortaDataFailure(iErr));
    }
}

export function* watchApriPortaDataRequest() {
    console.log('watchApriPortaDataRequest!');
    yield takeEvery(ApriPortaActionTypes.APRIPORTA_DATA_REQUEST, apriPortaDataRequestSaga);
}

export function* apriPortaApriSaga(action: ApriPortaApri) {
    try {
        console.log('saga apri porta ...');
        let ret: ICitofono = yield call([ApiClient.getInstance(), 'apriPortaCliente'], action.uuid);
        yield put(apriPortaApriSuccess(ret));
        console.log('Apri porta OK');
    } catch (error) {
        console.log('ApriPorta ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(apriPortaApriFailure(iErr));
    }
}

export function* watchApriPortaApri() {
    console.log('watchApriPortaApri!');
    yield takeEvery(ApriPortaActionTypes.APRIPORTA_APRI, apriPortaApriSaga);
}
