import { all } from 'redux-saga/effects';
import {
  watchCheckLoginRequest,
  watchLoginFailure,
  watchLoginRequest,
  watchLoginSuccess,
  watchLogout
} from './controller/login/Sagas';

import {
  watchHomeDataRequest
} from './controller/home/Sagas';
import { watchApriPortaApri, watchApriPortaDataRequest } from './controller/apriporta/Sagas';
import { watchAdminChPswRequest, watchAdminUpdRequest } from './controller/admin/Sagas';
import { watchCitofoniApriRequest, watchCitofoniUpdRequest } from './controller/citofoni/Sagas';
import { watchClientiAddNewRequest, watchClientiCitofonoDelRequest, watchClientiAddShowRequest, watchClientiCitofonoSLRequest, watchClientiCitofonoUpdRequest, watchClientiUpdRequest } from './controller/clienti/Sagas';


export default function* rootSaga() {
  yield all([
    watchLoginRequest(),
    watchCheckLoginRequest(),
    watchLoginSuccess(),
    watchLoginFailure(),
    watchLogout(),
    watchHomeDataRequest(),
    watchApriPortaDataRequest(),
    watchApriPortaApri(),
    watchCitofoniUpdRequest(),
    watchAdminUpdRequest(),
    watchAdminChPswRequest(),
    watchCitofoniApriRequest(),
    watchClientiUpdRequest(),
    watchClientiCitofonoUpdRequest(),
    watchClientiCitofonoSLRequest(),
    watchClientiAddShowRequest(),
    watchClientiAddNewRequest(),
    watchClientiCitofonoDelRequest()
  ]);
}