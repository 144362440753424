import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { Checkbox, DatePicker, DefaultButton, ITag, Label, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TagPicker, Text, TextField } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, ICliente, IClienteCitofono } from '../../api/Models';
import { ClientiAction, clientiAddHide, ClientiAddHide, clientiAddNewRequest, ClientiAddNewRequest } from '../controller/clienti/Actions';
import { DateHelper } from '../util/DateHelper';

interface IAddClientePanelProps {
  errorMsg: string | null;
  clienti: ICliente[] | null;
  citofono: ICitofono | null;
  showPanel: boolean;
  close: () => ClientiAddHide;
  crea: (cliente: ICliente | null, clienteCitofono: IClienteCitofono) => ClientiAddNewRequest;
}

interface IAddClientePanelState {
  newCli: boolean;
  selCli: ITag | null;
  nome: string;
  email: string;
  telefono: string;
  info: string;
  dataInizio: number | null;
  dataFine: number | null;
  error: string | null;
}

interface IClienteTag extends ITag {
  cliente: ICliente;
}

class AddClientePanel extends React.Component<IAddClientePanelProps, IAddClientePanelState> {
  constructor(props: IAddClientePanelProps) {
    super(props);
    this.state = {
      newCli: false,
      selCli: null,
      nome: '',
      email: '',
      telefono: '',
      info: '',
      dataInizio: Math.round(Date.now() / 1000),
      dataFine: null,
      error: null
    };
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.onRenderFooterContent = this.onRenderFooterContent.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeNewCli = this.onChangeNewCli.bind(this);
    this.searchCliente = this.searchCliente.bind(this);
    this.onSelChangeCliente = this.onSelChangeCliente.bind(this);
    this.onSelectDataInizio = this.onSelectDataInizio.bind(this);
    this.onSelectDataFine = this.onSelectDataFine.bind(this);
    this.onChangeInfo = this.onChangeInfo.bind(this);
  }

  private confirm() {
    // Se nuovo cliente
    if (this.state.newCli) {
      // Verifico che i campi obbligatori del cliente siano impostati
      if (!this.state.nome || this.state.nome.trim().length === 0) {
        this.setState({ error: 'Campo nome obbligatorio.' });
        return;
      }
    } else {
      // Verifico che sia stato selezionato un cliente
      if (!this.state.selCli) {
        this.setState({ error: 'Selezionare il cliene da abilitare.' });
        return;
      }
    }
    // Devo verificare le date inserite
    if (!this.state.dataInizio) {
      this.setState({ error: 'Campo Data Inizio obbligatorio.' });
      return;
    }
    let dIni = DateHelper.fixDataInizio(this.state.dataInizio);
    let dFine = DateHelper.fixDataDine(this.state.dataFine);
    if (dFine && dFine < dIni) {
      this.setState({ error: 'Il campo Data Fine deve essere successivo a Data Inizio.' });
      return;
    }
    // Devo normalizzare gli orari
    // Data inizio 00:00
    // Data fine 23:59
    let cliCit: IClienteCitofono = {
      idClienteCitofono: 0,
      idCliente: 0,
      idCitofono: this.props.citofono?.idCitofono || 0,
      dataInizio: dIni,
      dataFine: dFine,
      idAttivazione: ''
    };
    // Chiama la funzione di salva dati citofono
    if (this.state.newCli) {
      // Creao sia il cliente che cliente-citofono
      let cli: ICliente = {
        idCliente: 0,
        nome: this.state.nome,
        email: this.state.email,
        telefono: this.state.telefono,
        info: this.state.info,
        citofoni: []
      }
      this.props.crea(cli, cliCit);
    } else {
      // Creo soli il cliente-citofono, il cliente è già censito
      cliCit.idCliente = (this.state.selCli as IClienteTag).cliente.idCliente;
      this.props.crea(null, cliCit)
    }
  }

  private close() {
    // Azzero i campi per evitare che rimangano valorizzati tra due invocazioni
    this.setState({ error: null, newCli: false, selCli: null, nome: '', email: '', telefono: '', info:'', dataInizio: Math.round(Date.now() / 1000), dataFine: null });
    // Chiamo il metodo di chiusura
    this.props.close();
  }
  private onRenderFooterContent() {
    return (
      <div>
        <PrimaryButton key="confimDglApprovaBtn" onClick={this.confirm} text="Salva" />
        <DefaultButton key="undoDglApprovaBtn" onClick={this.close} text="Chiudi" />
      </div>
    );
  }

  private onChangeNewCli(ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, checked?: boolean | undefined) {
    this.setState({ newCli: checked || false, selCli: null, nome: '', email: '', telefono: '', info: '' });
  }

  private onChangeNome(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ nome: newValue || '' });
  }

  private onChangeEmail(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ email: newValue || '' });
  }

  private onChangeTelefono(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ telefono: newValue || '' });
  }

  private onChangeInfo(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ info: newValue || '' });
  }

  private searchCliente(filter: string, selectedItems?: ITag[] | undefined): ITag[] | Promise<ITag[]> {
    if (this.props.clienti) {
      let cliL = this.props.clienti;
      if (filter || filter.trim().length > 0) {
        cliL = cliL.filter((cli) => {
          if (cli) {
            if (cli.nome && cli.nome.toLowerCase().indexOf(filter) !== -1) {
              return true;
            }
            if (cli.email && cli.email.toLowerCase().indexOf(filter) !== -1) {
              return true;
            }
            if (cli.telefono && cli.telefono.toLowerCase().indexOf(filter) !== -1) {
              return true;
            }
          }
          return false;
        });
      }

      return cliL.map<ITag>((cli) => {
        return { name: cli.nome, key: cli.idCliente, cliente: cli };
      });
    }
    return [];
  }

  private onSelChangeCliente(items?: ITag[] | undefined) {
    if (items && items.length > 0) {
      let cliTag = items[0] as IClienteTag;
      this.setState({ selCli: cliTag, nome: cliTag.cliente.nome, email: cliTag.cliente.email, telefono: cliTag.cliente.telefono, info: cliTag.cliente.info });
    } else {
      this.setState({ selCli: null, nome: '', email: '', telefono: '', info: '' });
    }
  }

  private onSelectDataInizio(date: Date | null | undefined): void {
    this.setState({ dataInizio: date ? Math.round(date.getTime() / 1000) : null });
  }

  private onSelectDataFine(date: Date | null | undefined): void {
    this.setState({ dataFine: date ? Math.round(date.getTime() / 1000) : null });
  }

  public render(): JSX.Element {

    return (
      <Panel
        isOpen={this.props.showPanel}
        hasCloseButton={false}
        type={PanelType.smallFixedFar}
        headerText='Abilita citofono'
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {this.props.errorMsg && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.props.errorMsg}
            </MessageBar>
          )}
          {this.state.error && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.state.error}
            </MessageBar>
          )}
          <Text variant='small'>Abilita un cliente al seguente citofono:</Text>
          <Text variant='xLarge'>{this.props.citofono?.denominazione}</Text>
          <Label>Cerca il cliente:</Label>
          <TagPicker
            itemLimit={1}
            onResolveSuggestions={this.searchCliente}
            selectedItems={this.state.selCli ? [this.state.selCli] : []}
            onChange={this.onSelChangeCliente}
            disabled={this.state.newCli}
          />
          <Checkbox
            label="Crea un nuovo cliente"
            checked={this.state.newCli}
            onChange={this.onChangeNewCli}
          />

          <TextField
            label="Nome"
            value={this.state.nome}
            onChange={this.onChangeNome}
            maxLength={200}
            required
            readOnly={!this.state.newCli}
          />
          <TextField
            label="Email"
            value={this.state.email}
            onChange={this.onChangeEmail}
            maxLength={200}
            readOnly={!this.state.newCli}
          />
          <TextField
            label="Telefono"
            value={this.state.telefono}
            onChange={this.onChangeTelefono}
            maxLength={200}
            readOnly={!this.state.newCli}
          />
          <TextField
            label="Altre informazioni"
            value={this.state.info}
            onChange={this.onChangeInfo}
            rows={5}
            multiline
            readOnly={!this.state.newCli}
          />
          <Label>Periodo abilitazione citofono</Label>
          <Label>Data inizio*</Label>
          <DatePicker
            minDate={new Date()}
            formatDate={(d) => d ? d.toLocaleString('it-IT', { dateStyle: 'short' }) : ''}
            value={(this.state.dataInizio) ? new Date(this.state.dataInizio * 1000) : undefined}
            onSelectDate={this.onSelectDataInizio}
            placeholder="Data inizio abilitazione"
          />
          <Label>Data fine</Label>
          <DatePicker
            minDate={new Date()}
            formatDate={(d) => d ? d.toLocaleString('it-IT', { dateStyle: 'short' }) : ''}
            value={(this.state.dataFine) ? new Date(this.state.dataFine * 1000) : undefined}
            onSelectDate={this.onSelectDataFine}
            placeholder="Data fine abilitazione"
          />
        </Stack>
      </Panel>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.clienti?.error,
    clienti: store.clienti?.clienti,
    showPanel: store.clienti?.showAddCli
  };
};

const MapDispatchToProps = (dispatch: Dispatch<ClientiAction>) => ({
  close: () => dispatch(clientiAddHide()),
  crea: (cliente: ICliente | null, clienteCitofono: IClienteCitofono) => dispatch(clientiAddNewRequest(cliente, clienteCitofono))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(AddClientePanel);