import React from 'react';
import { Redirect } from 'react-router'
import { Dispatch } from "redux";
import '../../App.css';
import { MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { IUtente } from '../../api/Models';
import { Action, checkLoginRequest, CheckLoginRequest, loginRequest, LoginRequest } from '../controller/login/Actions';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ApiClient } from '../../api/ApiClient';
import Header from './Header';

interface ILoginViewProps {
  user: IUtente | null;
  errorMsg: string | null;
  checkLogin: () => CheckLoginRequest;
  login: (userid: string, psw: string) => LoginRequest;
}

interface ILoginViewState {
  userid: string;
  psw: string;
  errUserid?: string;
  errPsw?: string;
}

class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {
  constructor(props: ILoginViewProps) {
    super(props);
    this.state = {
      userid: '',
      psw: ''
    };
    this.onChangePsw = this.onChangePsw.bind(this);
    this.onChangeUserid = this.onChangeUserid.bind(this);
    this.doLogin = this.doLogin.bind(this);
  }

  public componentDidMount() {
    if (this.props.user == null && ApiClient.getInstance().hasApiToken()) {
      this.props.checkLogin();
    }
  }

  private onChangeUserid(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ userid: newValue || '' });
  }

  private onChangePsw(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
    this.setState({ psw: newValue || '' });
  }

  private doLogin() {
    if (!this.state.userid || this.state.userid.trim().length === 0) {
      this.setState({ errUserid: 'Cambo obbligatorio.', errPsw: '' });
      return;
    }
    if (!this.state.psw || this.state.psw.trim().length === 0) {
      this.setState({ errPsw: 'Cambo obbligatorio.', errUserid: '' });
      return;
    }
    this.setState({ errPsw: '', errUserid: '' });
    this.props.login(this.state.userid, this.state.psw);
  }

  public render(): JSX.Element {
    if (this.props.user) {
      return <Redirect to="/home" />;
    }
    let hasError = (this.props.errorMsg && this.props.errorMsg.trim().length > 0);
    let notLogged = !ApiClient.getInstance().hasApiToken();
    return (
      <Stack className="LoginView" horizontal={false}>
        <Header title='Gestione citofoni'/>
        {notLogged &&
          <form onSubmit={(ev) => ev.preventDefault()}>
            {hasError &&
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.props.errorMsg}
            </MessageBar>
            }
            <TextField
              label="Userid"
              value={this.state.userid}
              onChange={this.onChangeUserid}
              maxLength={200}
              required
              errorMessage={this.state.errUserid}              
            />
            <TextField
              label="Password"
              type='password'
              value={this.state.psw}
              onChange={this.onChangePsw}
              maxLength={200}
              required
              errorMessage={this.state.errPsw}
            />

            <PrimaryButton type='submit' onClick={() => this.doLogin()} styles={{root:{marginTop: '5px'}}}>Accedi</PrimaryButton>
          </form>
        }
        {!notLogged &&
          <p>Collegamento in corso. Attendere....</p>
        }
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    user: store.auth?.user,
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  checkLogin: () => dispatch(checkLoginRequest()),
  login: (userid: string, psw: string) => dispatch(loginRequest(userid, psw))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(LoginView);