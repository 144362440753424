import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, ICliente } from '../../api/Models';
import { ClientiAction, clienteHide, ClienteHide, ClientiUpdRequest, clientiUpdRequest } from '../controller/clienti/Actions';

interface IClientePanelProps {
  errorMsg: string | null;
  citofoni: ICitofono[] | undefined;
  cliente: ICliente | null;
  close: () => ClienteHide;
  aggiornaCliente: (cliente: ICliente) => ClientiUpdRequest;
}

interface IClientePanelState {
  idCliente: number | null;
  nome: string;
  email: string;
  telefono: string;
  info: string
  error: string | null;
}

class ClientePanel extends React.Component<IClientePanelProps, IClientePanelState> {
  constructor(props: IClientePanelProps) {
    super(props);
    this.state = {
      idCliente: this.props.cliente?.idCliente || null,
      nome: this.props.cliente?.nome || '',
      email: this.props.cliente?.email || '',
      telefono: this.props.cliente?.telefono || '',
      info: this.props.cliente?.info || '',
      error: null
    };
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.onRenderFooterContent = this.onRenderFooterContent.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeInfo = this.onChangeInfo.bind(this);
  }

  static getDerivedStateFromProps(props: IClientePanelProps, state: IClientePanelState) {
    if (props.cliente) {
      if (props.cliente.idCliente !== state.idCliente) {
        return {
          idCliente: props.cliente?.idCliente,
          nome: props.cliente?.nome,
          email: props.cliente?.email,
          telefono: props.cliente?.telefono,
          info: props.cliente?.info,
          error: null
        };
      }
    } else {
      if (state.idCliente) {
        return {
          idCliente: null,
          nome: '',
          email: '',
          telefono: '',
          info: '',
          error: null
        }
      }
    }

    return null;
  }

  private confirm() {
    // Valido i campi
    if (!this.state.nome || this.state.nome.trim().length === 0) {
      this.setState({ error: 'Campo nome obbligatorio.' });
      return;
    }
    // Chiama la funzione di salva dati citofono
    if (this.props.cliente) {
      let cliUpd: ICliente = { ...this.props.cliente };
      cliUpd.nome = this.state.nome;
      cliUpd.email = this.state.email;
      cliUpd.telefono = this.state.telefono;
      cliUpd.info = this.state.info;
      this.props.aggiornaCliente(cliUpd);
    }
  }

  private close() {
    // Azzero i campi per evitare che rimangano valorizzati tra due invocazioni
    this.setState({ error: null, nome: '', email: '', telefono: '', info: '' });
    // Chiamo il metodo di chiusura
    this.props.close();
  }
  private onRenderFooterContent() {
    return (
      <div>
        <PrimaryButton key="confimDglApprovaBtn" onClick={this.confirm} text="Salva" />
        <DefaultButton key="undoDglApprovaBtn" onClick={this.close} text="Chiudi" />
      </div>
    );
  }

  private onChangeNome = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ nome: newValue || '' });
  }

  private onChangeEmail = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ email: newValue || '' });
  }

  private onChangeTelefono = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ telefono: newValue || '' });
  }

  private onChangeInfo = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ info: newValue || '' });
  }

  public render(): JSX.Element {

    return (
      <Panel
        isOpen={this.props.cliente != null}
        hasCloseButton={false}
        type={PanelType.smallFixedFar}
        headerText='Modifica dati cliente'
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {this.state.error && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.state.error}
            </MessageBar>
          )}
          <TextField
            label="Nome"
            value={this.state.nome}
            onChange={this.onChangeNome}
            maxLength={200}
            required
          />
          <TextField
            label="Email"
            value={this.state.email}
            onChange={this.onChangeEmail}
            maxLength={200}
          />
          <TextField
            label="Telefono"
            value={this.state.telefono}
            onChange={this.onChangeTelefono}
            maxLength={200}
          />
          <TextField
            label="Altre informazioni"
            value={this.state.info}
            onChange={this.onChangeInfo}
            rows={5}
            multiline
          />          
        </Stack>
      </Panel>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.clienti?.error,
    citofoni: store.home?.homeData?.citofoni,
    cliente: store.clienti?.cliente
  };
};

const MapDispatchToProps = (dispatch: Dispatch<ClientiAction>) => ({
  close: () => dispatch(clienteHide()),
  aggiornaCliente: (cliente: ICliente) => dispatch(clientiUpdRequest(cliente))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(ClientePanel);