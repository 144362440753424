import { ErrorResponse } from "./ErrorResponse";
import { IApriPortaResponse, ICitofono, ICliente, IClienteCitofono, IHomeResponse, IUtente } from "./Models";

export class ApiClient {

    // https://citofono.azurewebsites.net/api
    private static baseUrl: string = process.env.REACT_APP_API_BASE_URL || window.location.protocol + '//' + window.location.host;
    private static apiKey: string | undefined = process.env.REACT_APP_API_KEY;

    private static instance: ApiClient = new ApiClient();

    static getInstance(): ApiClient {
        return ApiClient.instance;
    }

    private apiToken: string | null = null;

    private constructor() {
    }

    private static API_TOKEN_SESSION_NAME = 'fe_apiToken';

    private getApiToken(): string | null {
        if (this.apiToken == null) {
            // Provo a recuperare il token dalla sessione
            this.apiToken = window.sessionStorage.getItem(ApiClient.API_TOKEN_SESSION_NAME);
        }
        return this.apiToken;
    }
    public clearApiToken() {
        this.apiToken = null;
        window.sessionStorage.removeItem(ApiClient.API_TOKEN_SESSION_NAME);
    }
    private setApiToken(token: string) {
        this.apiToken = token;
        window.sessionStorage.setItem(ApiClient.API_TOKEN_SESSION_NAME, token);
    }

    public hasApiToken(): boolean {
        return (this.getApiToken() != null);
    }
    private getURL(apiPath: string, params: string[][]): string {
        let qParams = new URLSearchParams(params);
        let retUrl = new URL(ApiClient.baseUrl + apiPath);
        qParams.forEach((val, key) => {
            retUrl.searchParams.append(key, val);
        })

        return retUrl.toString();
    }

    private getRequestInit() {
        let ret: RequestInit = {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        };
        let headers: HeadersInit = {
            'Content-Type': 'application/json'
        };
        if (this.hasApiToken()) {
            headers['Authorization'] = 'Bearer ' + this.getApiToken();
        }
        if (ApiClient.apiKey) {
            headers['apikey'] = ApiClient.apiKey;
        }

        ret.headers = headers;
        return ret;
    }

    public async login(userid: string, psw: string): Promise<IUtente> {
        this.clearApiToken();
        let reqInit = this.getRequestInit();
        reqInit.method = 'POST';
        reqInit.body = JSON.stringify({ userid: userid, psw: psw });
        const response = await fetch(this.getURL('/citofono/login', []), reqInit);
        if (response.ok) {
            let user = await response.json() as IUtente;
            if (user && user.token) {
                this.setApiToken(user.token);
                return user;
            }
            throw new ErrorResponse('Non autenticato.', response.status, 'Dati risposta non presenti');
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async getMe(): Promise<IUtente> {
        const response = await fetch(this.getURL('/citofono/admin/me', []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async cambiaPsw(oldPsw: string, newPsw: string) {
        let reqInit = this.getRequestInit();
        reqInit.method = 'POST';
        reqInit.body = JSON.stringify({ oldPsw: oldPsw, newPsw: newPsw });
        const response = await fetch(this.getURL('/citofono/admin/me/password', []), reqInit);
        if (response.ok) {
            return;
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                let resp = await response.text();
                console.log('Body ricevuto con errore', resp);
                throw new ErrorResponse(resp || 'Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async aggiornaUtente(user: IUtente): Promise<IUtente> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'PATCH';
        reqInit.body = JSON.stringify(user);
        const response = await fetch(this.getURL('/citofono/admin/utente/' + user.idUtente, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async getHomeData(): Promise<IHomeResponse> {
        const response = await fetch(this.getURL('/citofono/admin/home', []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async aggiornaCitofono(cit: ICitofono): Promise<ICitofono> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'PATCH';
        reqInit.body = JSON.stringify(cit);
        const response = await fetch(this.getURL('/citofono/admin/citofono/' + cit.idCitofono, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async apriPorta(id: number): Promise<ICitofono> {
        if (!id) {
            console.log('ID porta non fornito.');
            throw new ErrorResponse('ID porta non fornito.', 400, 'Parametri invalidi');
        }
        const response = await fetch(this.getURL(`/citofono/admin/citofono/${id}/apri`, []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async verifica(id: number): Promise<ICitofono> {
        if (!id) {
            console.log('ID porta non fornito.');
            throw new ErrorResponse('ID porta non fornito.', 400, 'Parametri invalidi');
        }
        const response = await fetch(this.getURL(`/citofono/admin/citofono/${id}/verifica`, []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async sendLink(idClienteCitofono: number): Promise<string> {
        if (!idClienteCitofono) {
            console.log('ID cliente citofno non fornito.');
            throw new ErrorResponse('ID cliente citofono non fornito.', 400, 'Parametri invalidi');
        }
        const response = await fetch(this.getURL(`/citofono/admin/cliente/citofono/${idClienteCitofono}/sendlink`, []), this.getRequestInit());
        if (response.ok) {
            return response.text(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async getClienti(): Promise<ICliente[]> {
        const response = await fetch(this.getURL('/citofono/admin/cliente', []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async nuovoCliente(cli: ICliente): Promise<ICliente> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'POST';
        reqInit.body = JSON.stringify(cli);
        const response = await fetch(this.getURL('/citofono/admin//cliente', []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async aggiornaCliente(cli: ICliente): Promise<ICliente> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'PATCH';
        reqInit.body = JSON.stringify(cli);
        const response = await fetch(this.getURL('/citofono/admin/cliente/' + cli.idCliente, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async nuovoClienteCitofono(clicit: IClienteCitofono): Promise<IClienteCitofono> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'POST';
        reqInit.body = JSON.stringify(clicit);
        const response = await fetch(this.getURL(`/citofono/admin/cliente/${clicit.idCliente}/citofono`, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async aggiornaClienteCitofono(clicit: IClienteCitofono): Promise<IClienteCitofono> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'PATCH';
        reqInit.body = JSON.stringify(clicit);
        const response = await fetch(this.getURL(`/citofono/admin/cliente/citofono/${clicit.idClienteCitofono}`, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async nuovoClienteEClienteCitofono(cli: ICliente, clicit: IClienteCitofono): Promise<IClienteCitofono> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'POST';
        reqInit.body = JSON.stringify({ cliente: cli, clienteCitofono: clicit });
        const response = await fetch(this.getURL(`/citofono/admin/cliente/citofono`, []), reqInit);
        if (response.ok) {
            return response.json();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async eliminaClienteCitofono(idClienteCitofono: number): Promise<string> {
        let reqInit = this.getRequestInit();
        reqInit.method = 'DELETE';
        const response = await fetch(this.getURL(`/citofono/admin/cliente/citofono/${idClienteCitofono}`, []), reqInit);
        if (response.ok) {
            return response.text();
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                this.clearApiToken();
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    //
    // Api usati dal cliente, con la funzione apriPorta
    // Sono guori sicurezza e si utilizza uuid per identificare e bloccare le chiamate invalide
    //
    public async getApriPortaInfo(uuid: string): Promise<IApriPortaResponse> {
        const response = await fetch(this.getURL('/citofono/porta/' + encodeURIComponent(uuid), []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }

    public async apriPortaCliente(uuid: string): Promise<IApriPortaResponse> {
        const response = await fetch(this.getURL('/citofono/porta/' + encodeURIComponent(uuid) + '/apri', []), this.getRequestInit());
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }
}