import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, Separator, Stack, TextField } from '@fluentui/react';
import { Action } from '../controller/login/Actions';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, ILogEvento, TipoEvento, TipoNotifica } from '../../api/Models';

interface ILogEventiListProps {
  errorMsg: string | null;
  eventi: ILogEvento[];
  citofoni?: ICitofono[];
}

interface ILogEventiListState {
  eventi: ILogEvento[];
}
const controlStyles = {
  root: {
    margin: '5px 10px 0px 0 !important',
    maxWidth: '300px',
  },
};

class LogEventiList extends React.Component<ILogEventiListProps, ILogEventiListState> {
  constructor(props: ILogEventiListProps) {
    super(props);
    this.state = {
      eventi: props.eventi
    };
    this.onChangeText = this.onChangeText.bind(this);
    this.getKey = this.getKey.bind(this);
    this.onItemInvoked = this.onItemInvoked.bind(this);
  }

  private onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    this.setState({
      eventi: this.getFilteredItems(text),
    });
  };

  private getTipoEventoDes(idTipoEvento: TipoEvento) {
    let retVal = '';
    if (idTipoEvento) {
      switch (idTipoEvento) {
        case TipoEvento.ApriPorta:
          retVal = 'Apri porta';
          break;
        case TipoEvento.Campanello:
          retVal = 'Campanello';
          break;
        case TipoEvento.CitofonoConnesso:
          retVal = 'Citofono connesso';
          break;
        case TipoEvento.CitofonoDisconnesso:
          retVal = 'Citofono disconnesso';
          break;
        case TipoEvento.NotificaApriPorta:
          retVal = 'Notifica apri porta';
          break;
        case TipoEvento.NotificaCampanello:
          retVal = 'Notifica campanello';
          break;
        case TipoEvento.NotificaLink:
          retVal = 'Notifica Link';
          break;
        case TipoEvento.NotificaPortaAperta:
          retVal = 'Notifica porta aperta';
          break;
        case TipoEvento.PortaAperta:
          retVal = 'Porta aperta';
          break;
        case TipoEvento.Verifica:
          retVal = 'Verifica connessione';
          break;
        case TipoEvento.Conferma:
          retVal = 'Conferma connessione';
          break;
      }
    }
    return retVal;
  }


  private getTipoNotificaDes(idTipoNotifica: TipoNotifica) {
    let retVal = '';
    if (idTipoNotifica) {
      switch (idTipoNotifica) {
        case TipoNotifica.SMS:
          retVal = 'SMS';
          break;
        case TipoNotifica.Mail:
          retVal = 'Mail';
          break;
      }
    }
    return retVal;
  }

  private getFilteredItems(searchTxt: string | undefined) {
    if (this.props.eventi == null || !searchTxt || searchTxt.trim().length === 0) {
      return this.props.eventi;
    }
    let sTxt = searchTxt.trim().toLowerCase();
    return this.props.eventi.filter((elem) => {
      if (elem) {
        if (elem.ts && new Date(elem.ts * 1000).toLocaleString('it-IT').indexOf(sTxt) !== -1) {
          return true;
        }
        let tipoEvt = this.getTipoEventoDes(elem.idTipoEvento);
        if (tipoEvt && tipoEvt.toLowerCase().indexOf(sTxt) !== -1) {
          return true;
        }
        if (elem.idCitofono && this.props.citofoni) {
          let cit = this.props.citofoni.find((cit) => cit.idCitofono === elem.idCitofono);
          if (cit && cit.denominazione && cit.denominazione.toLocaleLowerCase().indexOf(sTxt) !== -1) {
            return true;
          }
        }
        if (elem.descrizione && elem.descrizione.toLowerCase().indexOf(sTxt) !== -1) {
          return true;
        }
      }
      return false;
    }, this);
  }

  private getKey(item: ILogEvento, index?: number): string {
    return '' + item.idEvento;
  }
  private onItemInvoked(item: ILogEvento): void {
    alert(`Item invoked: ${item.descrizione}`);
  }
  public render(): JSX.Element {
    const columns: IColumn[] = [
      {
        key: 'col1',
        name: 'Data/ora',
        fieldName: 'ts',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: () => { },
        isPadded: false,
        onRender: (item: ILogEvento) => {
          if (item.ts && item.ts > 0) {
            return new Date(item.ts * 1000).toLocaleString('it-IT');
          }
        },
      },
      {
        key: 'col2',
        name: 'Tipo',
        fieldName: 'idTipoEvento',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: () => { },
        isPadded: false,
        onRender: (item: ILogEvento) => {
          return this.getTipoEventoDes(item.idTipoEvento);
        }
      },
      {
        key: 'col3',
        name: 'Citofono',
        fieldName: 'idCitofono',
        minWidth: 120,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: () => { },
        isPadded: true,
        onRender: (item: ILogEvento) => {
          if (item.idCitofono && this.props.citofoni) {
            let cit = this.props.citofoni.find((cit) => cit.idCitofono === item.idCitofono);
            return cit?.denominazione;
          }
        },
      },
      {
        key: 'col4',
        name: 'Cliente',
        fieldName: 'nomeCliente',
        minWidth: 120,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: () => { },
        isPadded: true
      },
      {
        key: 'col5',
        name: 'Descrizione',
        fieldName: 'descrizione',
        minWidth: 100,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: () => { },
        isPadded: true,
      }
    ];
    return (
      <Stack horizontal={false}>
        <Separator alignContent='start'>{'Eventi delle ultime 12 ore'}</Separator>
        <TextField placeholder="cerca" onChange={this.onChangeText} styles={controlStyles} />
        <DetailsList
          items={this.state.eventi}
          compact={true}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={this.getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onItemInvoked={this.onItemInvoked}
        />
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(LogEventiList);