import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    HomeActionTypes,
    homeDataSuccess,
    HomeDataRequest,
    homeDataFailure
} from './Actions';
import { IHomeResponse } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';
import { loginFailure } from '../login/Actions';

export function* homeDataRequestSaga(action: HomeDataRequest) {
    try {
        console.log('saga home data...');
        let ret: IHomeResponse = yield call([ApiClient.getInstance(), 'getHomeData']);
        yield put(homeDataSuccess(ret));
        console.log('Home data OK');
    } catch (error) {
        console.log('HomeData ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(homeDataFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchHomeDataRequest() {
    console.log('watchHomeDataRequest!');
    yield takeEvery(HomeActionTypes.HOME_DATA_REQUEST, homeDataRequestSaga);
}

