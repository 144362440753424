import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { AdminAction, AdminChPswRequest, adminChPswRequest } from '../controller/admin/Actions';
import { ApiClient } from '../../api/ApiClient';

interface IUserChPswPanelProps {
  show: boolean;
  errorMsg: string | null;
  close: () => void;
  changePsw: (oldPsw: string, newPsw: string) => AdminChPswRequest;
}

interface IUserChPswPanelState {
  oldPsw: string;
  newPsw: string;
  checkNewPsw: string;
  error: string | null;
}

class UserChPswPanel extends React.Component<IUserChPswPanelProps, IUserChPswPanelState> {
  constructor(props: IUserChPswPanelProps) {
    super(props);
    this.state = {
      oldPsw: '',
      newPsw: '',
      checkNewPsw: '',
      error: null
    };
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.onRenderFooterContent = this.onRenderFooterContent.bind(this);
    this.onChangeOldPsw = this.onChangeOldPsw.bind(this);
    this.onChangeNewPsw = this.onChangeNewPsw.bind(this);
    this.onChangeCheckNewPsw = this.onChangeCheckNewPsw.bind(this);
  }

  private async confirm() {
    // Valido i campi
    if (!this.state.oldPsw || this.state.oldPsw.trim().length === 0) {
      this.setState({ error: 'Campo password attuale obbligatorio.' });
      return;
    }
    if (!this.state.newPsw || this.state.newPsw.trim().length === 0) {
      this.setState({ error: 'Campo password nuova obbligatorio.' });
      return;
    }
    if (!this.state.checkNewPsw || this.state.checkNewPsw.trim().length === 0) {
      this.setState({ error: 'Campo ripeti nuova obbligatorio.' });
      return;
    }
    if (this.state.newPsw !== this.state.checkNewPsw) {
      this.setState({ error: 'Attenzione: nuova password diversa da ripeti nuova password.' });
      return;
    }
    try {
      await ApiClient.getInstance().cambiaPsw(this.state.oldPsw, this.state.newPsw);
      this.close();
    } catch(err) {
      this.setState({error: err.message});
    }
  }

  private close() {
    // Azzero i campi per evitare che rimangano valorizzati tra due invocazioni
    this.setState({ error: null, oldPsw: '', newPsw: '', checkNewPsw: '' });
    // Chiamo il metodo di chiusura
    this.props.close();
  }
  private onRenderFooterContent() {
    return (
      <div>
        <PrimaryButton key="confimDglApprovaBtn" onClick={this.confirm} text="Conferma" />
        <DefaultButton key="undoDglApprovaBtn" onClick={this.close} text="Annulla" />
      </div>
    );
  }

  private onChangeOldPsw = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ oldPsw: newValue || '', error: null });
  }

  private onChangeNewPsw = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ newPsw: newValue || '', error: null });
  }

  private onChangeCheckNewPsw = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ checkNewPsw: newValue || '', error: null });
  }

  public render(): JSX.Element {

    return (
      <Panel
        isOpen={this.props.show}
        hasCloseButton={false}
        type={PanelType.smallFixedFar}
        headerText='Cambia password'
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 15 }}>
        {this.props.errorMsg && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.props.errorMsg}
            </MessageBar>
          )}
          {this.state.error && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.state.error}
            </MessageBar>
          )}
          <TextField
            label="Attuale"
            type='password'
            value={this.state.oldPsw}
            onChange={this.onChangeOldPsw}
            maxLength={200}
            required
          />
          <TextField
            label="Nuova"
            type='password'
            value={this.state.newPsw}
            onChange={this.onChangeNewPsw}
            maxLength={200}
            required
          />
          <TextField
            label="Ripeti nuova"
            type='password'
            value={this.state.checkNewPsw}
            onChange={this.onChangeCheckNewPsw}
            maxLength={200}
            required
          />
        </Stack>
      </Panel>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.admin?.error,
  };
};

const MapDispatchToProps = (dispatch: Dispatch<AdminAction>) => ({
  changePsw: (oldPsw: string, newPsw: string) => dispatch(adminChPswRequest(oldPsw, newPsw))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(UserChPswPanel);