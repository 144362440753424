import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { IStackTokens, MessageBar, MessageBarType, PrimaryButton, Stack, Text } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { IApriPortaResponse } from '../../api/Models';
import { ApriPortaAction, apriPortaApri, ApriPortaApri, apriPortaDataRequest, ApriPortaDataRequest } from '../controller/apriporta/Actions';
import { DateHelper } from '../util/DateHelper';

interface IApriPortaProps {
  uuid: string;
  apriResponse: IApriPortaResponse | null;
  errorMsg: string | null;
  getApriPortaInfo: (uuid: string) => ApriPortaDataRequest;
  apriPorta: (uuid: string) => ApriPortaApri;
}

interface IApriPortaState {
}

class ApriPorta extends React.Component<IApriPortaProps, IApriPortaState> {
  constructor(props: IApriPortaProps) {
    super(props);
    this.state = {
    };

  }

  public componentDidMount() {
    let uuid = this.getIDPorta();
    if (uuid && this.props.apriResponse == null && this.props.errorMsg == null) {
      this.props.getApriPortaInfo(uuid);
    }
  }

  private getIDPorta() {
    let idPorta = null;
    if (this.props.uuid && this.props.uuid.trim().length > 0) {
      idPorta = this.props.uuid.trim();
      if (idPorta.startsWith("?")) {
        idPorta = idPorta.substr(1);
      }
    }
    return idPorta;
  }

  public render(): JSX.Element {
    const customSpacingStackTokens: IStackTokens = {
      childrenGap: '10px',
      padding: 'm 5px'
    };

    return (
      <Stack styles={{ root: { border: '1px solid lightgray', maxWidth: '250px', margin: '20px' } }}>
        {(this.props.errorMsg && this.props.errorMsg.trim().length > 0) &&
        <MessageBar
          messageBarType={MessageBarType.error}
        >
          {this.props.errorMsg}
        </MessageBar>
        }
        {(!this.props.apriResponse && !this.props.errorMsg) &&
        <p>Verifica link in corso. Attendere.....</p>
        }
        {this.props.apriResponse &&
          <Stack styles={{ root: { margin: '0px 16px 0px 16px' } }} tokens={customSpacingStackTokens} >
                <Text variant={'large'}>{this.props.apriResponse.nomeCliente}</Text>
                <Text variant={'large'}>Benvenuto in {this.props.apriResponse.denominazione}</Text>
                <Text variant={'mediumPlus'}>Questa applicazione ti permette di aprire la porta del condominio</Text>
                {this.props.apriResponse.dataInizio &&
                <Text variant={'medium'}>dal {DateHelper.getDateString(this.props.apriResponse.dataInizio)}</Text>
                }
                {this.props.apriResponse.dataFine &&
                <Text variant={'medium'}>al { DateHelper.getDateString(this.props.apriResponse.dataFine)}</Text>
                }
                <PrimaryButton styles={{root: {height: '100px'}}} onClick={() => this.props.apriPorta(this.getIDPorta() || '')}>APRI</PrimaryButton>
          </Stack>      
        }
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    apriResponse: store.apriPorta?.apriResponse,
    errorMsg: store.apriPorta?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<ApriPortaAction>) => ({
  getApriPortaInfo: (uuid: string) => dispatch(apriPortaDataRequest(uuid)),
  apriPorta: (uuid: string) => dispatch(apriPortaApri(uuid))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(ApriPorta);