export class DateHelper {

    public static fixDataInizio(dataInizio: number): number {
        if (dataInizio) {
            let d = new Date(dataInizio * 1000);
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            dataInizio = Math.round(d.getTime() / 1000);
        }
        return dataInizio;
    }

    public static fixDataDine(dataFine?: number | null): number | undefined {
        if (dataFine) {
            let d = new Date(dataFine * 1000);
            d.setHours(23);
            d.setMinutes(59);
            d.setSeconds(59);
            d.setMilliseconds(0);
            dataFine = Math.round(d.getTime() / 1000);
        }
        return dataFine || undefined;
    }

    public static getDateString(d: number): string {
        let ret = '';
        if (d && d > 0) {
            ret = new Date(d * 1000).toLocaleDateString('it-IT');
        }
        return ret;
    }
}