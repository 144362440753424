import { Reducer } from 'redux';
import { ICliente, IClienteCitofono } from '../../../api/Models';

import { ClientiAction, ClientiActionTypes as Type } from './Actions';

export interface ClientiState {
  cliente: ICliente | null;
  clienteCitofono: IClienteCitofono | null;
  showAddCli: boolean;
  clienti: ICliente[];
  error: string | null;
}

export const initialState: ClientiState = {
  cliente: null,
  clienteCitofono: null,
  showAddCli: false,
  clienti: [],
  error: null
};

export const reducer: Reducer<ClientiState> =
  (state: ClientiState = initialState, action: ClientiAction) => {
    switch (action.type) {
      case Type.CLIENTE_SHOW:
        console.log('CLIENTE_SHOW reduce');
        return {
          ...state,
          cliente: action.cliente,
          error: null
        };
      case Type.CLIENTE_HIDE:
        console.log('CLIENTE_HIDE reduce');
        return {
          ...state,
          cliente: null,
          error: null
        };
      case Type.CLIENTE_UPD_REQUEST:
        console.log('CLIENTE_UPD_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLIENTE_UPD_SUCCESS:
        console.log('CLIENTE_UPD_SUCCESS reduce');
        return {
          ...state,
          cliente: null,
          error: null
        };
      case Type.CLIENTE_UPD_FAILURE:
        console.log('CLIENTE_UPD_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      case Type.CLICIT_SHOW:
        console.log('CLICIT_SHOW reduce');
        return {
          ...state,
          clienteCitofono: action.clienteCitofono,
          error: null
        };
      case Type.CLICIT_HIDE:
        console.log('CLICIT_HIDE reduce');
        return {
          ...state,
          clienteCitofono: null,
          error: null
        };
      case Type.CLICIT_UPD_REQUEST:
        console.log('CLICIT_UPD_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLICIT_UPD_SUCCESS:
        console.log('CLICIT_UPD_SUCCESS reduce');
        return {
          ...state,
          clienteCitofono: null,
          error: null
        };
      case Type.CLICIT_UPD_FAILURE:
        console.log('CLICIT_UPD_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      case Type.CLICIT_SL_REQUEST:
        console.log('CLICIT_SL_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLICIT_SL_SUCCESS:
        console.log('CLICIT_SL_SUCCESS reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLICIT_SL_FAILURE:
        console.log('CLICIT_SL_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: null
        };
      case Type.ADDCLI_SHOW_REQUEST:
        console.log('ADDCLI_SHOW_REQUEST reduce');
        return {
          ...state,
          showAddCli: false,
          clienti: [],
          error: null
        };
      case Type.ADDCLI_SHOW_SUCCESS:
        console.log('ADDCLI_SHOW_SUCCESS reduce');
        return {
          ...state,
          showAddCli: true,
          clienti: action.clienti,
          error: null
        };
      case Type.ADDCLI_SHOW_FAILURE:
        console.log('ADDCLI_SHOW_FAILURE reduce: ', action.error);
        return {
          ...state,
          showAddCli: false,
          clienti: [],
          error: null
        };
      case Type.ADDCLI_HIDE:
        console.log('ADDCLI_HIDE reduce');
        return {
          ...state,
          showAddCli: false,
          citofono: null,
          clienti: [],
          error: null
        };
      case Type.ADDCLI_NEW_REQUEST:
        console.log('ADDCLI_NEW_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.ADDCLI_NEW_SUCCESS:
        console.log('ADDCLI_NEW_SUCCESS reduce');
        return {
          ...state,
          showAddCli: false,
          clienti: [],
          error: null
        };
      case Type.ADDCLI_NEW_FAILURE:
        console.log('ADDCLI_NEW_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      case Type.CLICIT_DEL_REQUEST:
        console.log('CLICIT_DEL_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLICIT_DEL_SUCCESS:
        console.log('CLICIT_DEL_SUCCESS reduce');
        return {
          ...state,
          error: null
        };
      case Type.CLICIT_DEL_FAILURE:
        console.log('CLICIT_DEL_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: null
        };
      default:
        return state;
    }
  };

export default reducer;