import { IErrorResponse } from "../../../api/ErrorResponse";
import { IUtente } from "../../../api/Models";

export enum ActionTypes {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    CHKLOGIN_REQUEST = 'CHKLOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT = 'LOGOUT',
    ADMIN_UPD = 'ADMIN_UPD'
  }
  
  export interface LoginRequest {
    type: ActionTypes.LOGIN_REQUEST;
    userid: string,
    psw: string
  }
    
  export interface CheckLoginRequest {
    type: ActionTypes.CHKLOGIN_REQUEST;
  }

  export interface LoginSuccess {
    type: ActionTypes.LOGIN_SUCCESS;
    user: IUtente;
  }
  
  export interface LoginFailure {
    type: ActionTypes.LOGIN_FAILURE;
    error: IErrorResponse;
  }
  
  export interface Logout {
    type: ActionTypes.LOGOUT;
  }

  export interface AdminUpd {
    type: ActionTypes.ADMIN_UPD;
    user: IUtente;
  }

  export type Action =
    | LoginRequest
    | CheckLoginRequest
    | LoginSuccess
    | LoginFailure
    | Logout
    | AdminUpd
    ;
  
  // ---------------
  // Action Creators
  // ---------------
  
  export const loginRequest = (userid: string, psw: string): LoginRequest => ({
    type: ActionTypes.LOGIN_REQUEST,
    userid: userid,
    psw: psw
  });
  
  export const checkLoginRequest = (): CheckLoginRequest => ({
    type: ActionTypes.CHKLOGIN_REQUEST
  });

  export const loginSuccess = (user: IUtente): LoginSuccess => ({
    type: ActionTypes.LOGIN_SUCCESS,
    user: user,
  });
  
  export const loginFailure = (error: IErrorResponse): LoginFailure => ({
    type: ActionTypes.LOGIN_FAILURE,
    error: error,
  });
  
  export const logout = (): Logout => ({
    type: ActionTypes.LOGOUT,
  });

  export const adminUpd = (user: IUtente): AdminUpd => ({
    type: ActionTypes.ADMIN_UPD,
    user: user,
  });
