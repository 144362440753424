import { Reducer } from 'redux';
import { ICitofono } from '../../../api/Models';

import { CitofoniAction, CitofoniActionTypes as Type } from './Actions';

export interface CitofoniState {
  citofono: ICitofono | null;
  error: string | null;
}

export const initialState: CitofoniState = {
  citofono: null,
  error: null
};

export const reducer: Reducer<CitofoniState> =
  (state: CitofoniState = initialState, action: CitofoniAction) => {
    switch (action.type) {
      case Type.CITOFONO_SHOW:
        console.log('CITOFONO_SHOW reduce');
        return {
          ...state,
          citofono: action.citofono,
          error: null
        };
      case Type.CITOFONO_HIDE:
        console.log('CITOFONO_HIDE reduce');
        return {
          ...state,
          citofono: null,
          error: null
        };
      case Type.CIT_UPD_REQUEST:
        console.log('CIT_UPD_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CIT_UPD_SUCCESS:
        console.log('CIT_UPD_SUCCESS reduce');
        return {
          ...state,
          citofono: null,
          error: null
        };
      case Type.CIT_UPD_FAILURE:
        console.log('CIT_UPD_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      case Type.CIT_APRI_REQUEST:
        console.log('CIT_APRI_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.CIT_APRI_SUCCESS:
        console.log('CIT_APRI_SUCCESS reduce');
        return {
          ...state,
          error: null
        };
      case Type.CIT_APRI_FAILURE:
        console.log('CIT_APRI_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      default:
        return state;
    }
  };

export default reducer;