import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    ClientiActionTypes,
    ClientiUpdRequest,
    clientiUpdSuccess,
    clientiUpdFailure,
    ClientiCitofonoUpdRequest,
    clientiCitofonoUpdSuccess,
    clientiCitofonoUpdFailure,
    ClientiCitofonoSLRequest,
    clientiCitofonoSLSuccess,
    clientiCitofonoSLFailure,
    ClientiAddShowRequest,
    clientiAddShowSuccess,
    clientiAddShowFailure,
    ClientiAddNewRequest,
    clientiAddSNewSuccess,
    clientiAddNewFailure,
    ClientiCitofonoDelRequest,
    clientiCitofonoDelSuccess,
    clientiCitofonoDelFailure,
} from './Actions';
import { ICliente, IClienteCitofono } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';
import { loginFailure } from '../login/Actions';
import { homeDataRequest } from '../home/Actions';

export function* clientiUpdRequestSaga(action: ClientiUpdRequest) {
    try {
        console.log('saga aggiorna cliente...');
        let ret: ICliente = yield call([ApiClient.getInstance(), 'aggiornaCliente'], action.cliente);
        yield put(clientiUpdSuccess(ret));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Aggiorna cliente OK');
    } catch (error) {
        console.log('Aggiorna cliente ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiUpdFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiUpdRequest() {
    console.log('watchClientiUpdRequest!');
    yield takeEvery(ClientiActionTypes.CLIENTE_UPD_REQUEST, clientiUpdRequestSaga);
}

export function* clientiCitofonoUpdRequestSaga(action: ClientiCitofonoUpdRequest) {
    try {
        console.log('saga aggiorna cliente-citofono...');
        let ret: IClienteCitofono = yield call([ApiClient.getInstance(), 'aggiornaClienteCitofono'], action.clienteCitofono);
        yield put(clientiCitofonoUpdSuccess(ret));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Aggiorna cliente-citofono OK');
    } catch (error) {
        console.log('Aggiorna cliente-citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiCitofonoUpdFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiCitofonoUpdRequest() {
    console.log('watchClientiCitofonoUpdRequest!');
    yield takeEvery(ClientiActionTypes.CLICIT_UPD_REQUEST, clientiCitofonoUpdRequestSaga);
}

export function* clientiCitofonoSLRequestSaga(action: ClientiCitofonoSLRequest) {
    try {
        console.log('saga invia link cliente-citofono...');
        let ret: string = yield call([ApiClient.getInstance(), 'sendLink'], action.idClienteCitofono);
        yield put(clientiCitofonoSLSuccess(ret));
        console.log('Link cliente-citofono inviato OK');
    } catch (error) {
        console.log('Invio link cliente-citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiCitofonoSLFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiCitofonoSLRequest() {
    console.log('watchClientiCitofonoSLRequest!');
    yield takeEvery(ClientiActionTypes.CLICIT_SL_REQUEST, clientiCitofonoSLRequestSaga);
}

export function* clientiAddShowRequestSaga(action: ClientiAddShowRequest) {
    try {
        console.log('saga show add cliente a citofono...');
        let ret: ICliente[] = yield call([ApiClient.getInstance(), 'getClienti']);
        yield put(clientiAddShowSuccess(ret));
        console.log('Show add cliente OK');
    } catch (error) {
        console.log('Show add cliente ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiAddShowFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiAddShowRequest() {
    console.log('watchClientiAddShowRequest!');
    yield takeEvery(ClientiActionTypes.ADDCLI_SHOW_REQUEST, clientiAddShowRequestSaga);
}

export function* clientiAddNewRequestSaga(action: ClientiAddNewRequest) {
    try {
        console.log('saga add cliente/cliente-citofono...');
        let cli: ICliente | null = null;
        let cliCit: IClienteCitofono | null = null;
        if (action.cliente) {
            cli = yield call([ApiClient.getInstance(), 'nuovoClienteEClienteCitofono'], action.cliente, action.clienteCitofono);
        } else {
            cliCit = yield call([ApiClient.getInstance(), 'nuovoClienteCitofono'], action.clienteCitofono);
        }
        yield put(clientiAddSNewSuccess(cli, cliCit));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Add cliente/cliente-citofono OK');
    } catch (error) {
        console.log('Add cliente/cliente-citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiAddNewFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiAddNewRequest() {
    console.log('watchClientiAddNewRequest!');
    yield takeEvery(ClientiActionTypes.ADDCLI_NEW_REQUEST, clientiAddNewRequestSaga);
}

export function* clientiCitofonoDelRequestSaga(action: ClientiCitofonoDelRequest) {
    try {
        console.log('saga delete cliente-citofono...');
        let esito: string = yield call([ApiClient.getInstance(), 'eliminaClienteCitofono'], action.idClienteCitofono);
        yield put(clientiCitofonoDelSuccess(esito));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Delete cliente-citofono OK');
    } catch (error) {
        console.log('Delete cliente-citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(clientiCitofonoDelFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchClientiCitofonoDelRequest() {
    console.log('watchClientiCitofonoDelRequest!');
    yield takeEvery(ClientiActionTypes.CLICIT_DEL_REQUEST, clientiCitofonoDelRequestSaga);
}
