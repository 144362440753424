import React, { Dispatch } from 'react';
import { History } from 'history'
import { Route, Switch } from 'react-router'
import './App.css';
import { ConnectedRouter } from 'connected-react-router';
import LoginView from './app/pages/LoginView';
import HomeView from './app/pages/HomeView';
import { Action } from './app/controller/login/Actions';
import { AppState } from './app/RootReducer';
import { connect } from 'react-redux';
import ApriPorta from './app/pages/ApriPorta';
import CitofoniView from './app/pages/CitofoniView';

interface IAppProps {
  history: History;
}

interface IAppState {
}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
    };

  }

  public render(): JSX.Element {
    return (
      <ConnectedRouter history={this.props.history}>
          <Switch>
            <Route exact path="/apriporta" render={(props) => <ApriPorta uuid={props.location.search} />} />
            <Route exact path="/login" component={LoginView} />
            <Route exact path="/home" component={HomeView} />
            <Route exact path="/citofoni" component={CitofoniView} />
            <Route path="/" component={HomeView} />
          </Switch>
      </ConnectedRouter>
    );
  }
}


const MapStateToProps = (store: AppState) => {
  return {
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(App);