import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { ActionButton, FontIcon, IStackTokens, mergeStyles, Separator, Stack, Text } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { IUtente } from '../../api/Models';
import { Action, logout, Logout } from '../controller/login/Actions';
import UserAdminPanel from './UserAdminPanel';
import { AdminAction } from '../controller/admin/Actions';
import UserChPswPanel from './UserChPswPanel';

interface IUserAdminElemProps {
  errorMsg: string | null;
  user: IUtente | null;
  admin: IUtente;
  logout: () => Logout;
}

interface IUserAdminElemState {
  admin: IUtente | null;
  showChPsw: boolean;
}

class UserAdmin extends React.Component<IUserAdminElemProps, IUserAdminElemState> {
  constructor(props: IUserAdminElemProps) {
    super(props);
    this.state = {
      admin: null,
      showChPsw: false
    };
  }

  public render(): JSX.Element {
    const iconClass = mergeStyles({
      fontSize: 14,
      height: 14,
      width: 14,
      margin: '0 5px 0 0',
    });
    const customSpacingStackTokens: IStackTokens = {
      childrenGap: '10px',
      padding: 'm 5px'
    };
    return (
      <Stack styles={{ root: { border: '1px solid lightgray' } }}>
        <Stack styles={{ root: { margin: '0px 16px 0px 16px' } }} tokens={customSpacingStackTokens} >
          <Text variant={'large'}>{this.props.admin.nome}</Text>
          <Stack horizontal={true} styles={{ root: { marginTop: '15px !important' } }} verticalAlign='baseline'>
            <FontIcon iconName='Mail' className={iconClass} /><Text variant={'medium'}>{this.props.admin.email}</Text>
          </Stack>
          <Stack horizontal={true} verticalAlign='baseline'>
            <FontIcon iconName='Phone' className={iconClass} /><Text variant={'medium'}>{this.props.admin.telefono}</Text>
          </Stack>
        </Stack>
        <Separator />
        <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => this.setState({ admin: this.props.user })}>Modifica</ActionButton>
        <ActionButton iconProps={{ iconName: 'CodeEdit' }} onClick={() => this.setState({ showChPsw: true })}>Cambia password</ActionButton>
        <ActionButton iconProps={{ iconName: 'SignOut' }} onClick={() => this.props.logout()}>Esci</ActionButton>
        <UserAdminPanel user={this.state.admin} close={() => this.setState({ admin: null })} />
        <UserChPswPanel show={this.state.showChPsw} close={() => this.setState({ showChPsw: false })} />
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    user: store.auth?.user,
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action | AdminAction>) => ({
  logout: () => dispatch(logout())
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(UserAdmin);