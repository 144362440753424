import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono } from '../../api/Models';
import { CitofoniAction, citofoniUpdRequest, CitofoniUpdRequest, citofonoHide, CitofonoHide } from '../controller/citofoni/Actions';

interface ICitofonoPanelProps {
  errorMsg: string | null;
  citofono: ICitofono | null;
  close: () => CitofonoHide;
  aggiornaCitofono: (citofono: ICitofono) => CitofoniUpdRequest;
}

interface ICitofonoPanelState {
  idCitofono: number | null;
  denominazione: string;
  indirizzo: string;
  localita: string;
  cap: number | null;
  idIot: string;
  pubKeyIot: string;
  error: string | null;
}

class CitofonoPanel extends React.Component<ICitofonoPanelProps, ICitofonoPanelState> {
  constructor(props: ICitofonoPanelProps) {
    super(props);
    this.state = {
      idCitofono: this.props.citofono?.idCitofono || null,
      denominazione: this.props.citofono?.denominazione || '',
      indirizzo: this.props.citofono?.indirizzo || '',
      localita: this.props.citofono?.localita || '',
      cap: this.props.citofono?.cap || null,
      idIot: this.props.citofono?.idIot || '',
      pubKeyIot: this.props.citofono?.pubKeyIot || '',
      error: null
    };
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.onRenderFooterContent = this.onRenderFooterContent.bind(this);
    this.onChangeDenominazione = this.onChangeDenominazione.bind(this);
    this.onChangeIndirizzo = this.onChangeIndirizzo.bind(this);
    this.onChangeLocalita = this.onChangeLocalita.bind(this);
    this.onChangeCAP = this.onChangeCAP.bind(this);
    this.onChangeIdIot = this.onChangeIdIot.bind(this);
    this.onChangePubKeyIot = this.onChangePubKeyIot.bind(this);
  }

  static getDerivedStateFromProps(props: ICitofonoPanelProps, state: ICitofonoPanelState) {
    if (props.citofono) {
      if (props.citofono.idCitofono !== state.idCitofono) {
        return {
          idCitofono: props.citofono.idCitofono,
          denominazione: props.citofono.denominazione,
          indirizzo: props.citofono?.indirizzo,
          localita: props.citofono?.localita,
          cap: props.citofono?.cap,
          idIot: props.citofono?.idIot,
          pubKeyIot: props.citofono?.pubKeyIot,
          error: null
        };
      }
    } else {
      if (state.idCitofono) {
        return {
          idCitofono: null,
          denominazione: '',
          indirizzo: '',
          localita: '',
          cap: null,
          idIot: '',
          pubKeyIot: '',
          error: null
        }
      }
    }

    return null;
  }

  private confirm() {
    // Valido i campi
    if (!this.state.denominazione || this.state.denominazione.trim().length === 0) {
      this.setState({ error: 'Campo denominazione obbligatorio.' });
      return;
    }
    // Chiama la funzione di salva dati citofono
    console.log('confirm', this.state.idCitofono, this.props.citofono);
    if (this.state.idCitofono && this.props.citofono) {
      let citMod = { ...this.props.citofono };
      citMod.idCitofono = this.state.idCitofono;
      citMod.denominazione = this.state.denominazione;
      citMod.indirizzo = this.state.indirizzo;
      citMod.localita = this.state.localita;
      citMod.cap = this.state.cap || 0;
      citMod.idIot = this.state.idIot;
      citMod.pubKeyIot = this.state.pubKeyIot;
      this.props.aggiornaCitofono(citMod);
    }

  }

  private close() {
    // Azzero i campi per evitare che rimangano valorizzati tra due invocazioni
    this.setState({ error: null, denominazione: '', indirizzo: '', localita: '', cap: null, idIot: '', pubKeyIot: '' });
    // Chiamo il metodo di chiusura
    this.props.close();
  }
  private onRenderFooterContent() {
    return (
      <div>
        <PrimaryButton key="confimDglApprovaBtn" onClick={this.confirm} text="Salva" />
        <DefaultButton key="undoDglApprovaBtn" onClick={this.close} text="Chiudi" />
      </div>
    );
  }

  private onChangeDenominazione = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ denominazione: newValue || '' });
  }

  private onChangeIndirizzo = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ indirizzo: newValue || '' });
  }

  private onChangeLocalita = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ localita: newValue || '' });
  }

  private onChangeCAP = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let cap = Number(newValue) || null;
    this.setState({ cap: cap });
  }

  private onChangeIdIot = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ idIot: newValue || '' });
  }

  private onChangePubKeyIot = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ pubKeyIot: newValue || '' });
  }

  public render(): JSX.Element {

    return (
      <Panel
        isOpen={this.props.citofono != null}
        hasCloseButton={false}
        type={PanelType.smallFixedFar}
        headerText='Modifica dati citofono'
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {this.state.error && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.state.error}
            </MessageBar>
          )}
          <TextField
            label="Denominazione"
            value={this.state.denominazione}
            onChange={this.onChangeDenominazione}
            maxLength={200}
            required
          />
          <TextField
            label="Indirizzo"
            value={this.state.indirizzo}
            onChange={this.onChangeIndirizzo}
            maxLength={200}
          />
          <TextField
            label="Localita"
            value={this.state.localita}
            onChange={this.onChangeLocalita}
            maxLength={200}
          />
          <TextField
            label="CAP"
            value={this.state.cap ? this.state.cap.toString() : ''}
            maxLength={5}
            onChange={this.onChangeCAP}
            styles={{ fieldGroup: { width: 60 } }}
          />
          <TextField
            label="ID Citofono"
            value={this.state.idIot}
            onChange={this.onChangeIdIot}
            maxLength={20}
          />
          <TextField
            label="Certificato autenticazione"
            value={this.state.pubKeyIot}
            onChange={this.onChangePubKeyIot}
            rows={5}
            multiline
          />
        </Stack>
      </Panel>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.home?.error,
    citofono: store.citofoni?.citofono
  };
};

const MapDispatchToProps = (dispatch: Dispatch<CitofoniAction>) => ({
  close: () => dispatch(citofonoHide()),
  aggiornaCitofono: (citofono: ICitofono) => dispatch(citofoniUpdRequest(citofono))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(CitofonoPanel);