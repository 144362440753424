
export interface IErrorResponse {
  message: string;
  code: number;
  statusText: string;
  authCode?: string;
  authInteractive: boolean;
}

export class ErrorResponse extends Error implements IErrorResponse {
  code: number;
  statusText: string;
  authCode?: string;
  authInteractive: boolean = false;
  public constructor(message: string, code: number, statusText: string, authCode?: string, authInterative: boolean = false) {
    super ("Errore: " + message);
    this.code = code;
    this.statusText = statusText;
    this.authCode = authCode;
    this.authInteractive = authInterative;
  }

  public getIErrorResponse(): IErrorResponse {
    return {code: this.code, statusText: this.statusText, authCode: this.authCode, authInteractive: this.authInteractive, message: this.message};
  }
}