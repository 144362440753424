import { Reducer } from 'redux';

import { HomeAction, HomeActionTypes as Type } from './Actions';
import { IHomeResponse } from '../../../api/Models';
import { CitofoniAction, CitofoniActionTypes } from '../citofoni/Actions';
import { ClientiAction, ClientiActionTypes } from '../clienti/Actions';

export interface HomeState {
  homeData: IHomeResponse | null;
  error: string | null;
}

export const initialState: HomeState = {
  homeData: null,
  error: null
};

export const reducer: Reducer<HomeState> =
  (state: HomeState = initialState, action: HomeAction | CitofoniAction | ClientiAction) => {
    switch (action.type) {
      case Type.HOME_DATA_REQUEST:
        console.log('HOME_DATA_REQUEST reduce');
        return {
          ...state,
          homeData: null,
          error: null
        };
      case Type.HOME_DATA_SUCCESS:
        console.log('HOME_DATA_SUCCESS reduce');
        return {
          ...state,
          homeData: action.homeData,
          error: null
        };
      case Type.HOME_DATA_FAILURE:
        console.log('HOME_DATA_FAILURE reduce: ', action.error);
        return {
          ...state,
          homeData: null,
          error: action.error.message
        };
      case CitofoniActionTypes.CIT_APRI_SUCCESS:
      case ClientiActionTypes.ADDCLI_SHOW_SUCCESS:
      case ClientiActionTypes.CLICIT_SL_SUCCESS:
        console.log('Ok in una funzione citofono/cliente reduce');
        return {
          ...state,
          error: null
        };
      case CitofoniActionTypes.CIT_APRI_FAILURE:
      case ClientiActionTypes.ADDCLI_SHOW_FAILURE:
      case ClientiActionTypes.CLICIT_SL_FAILURE:
        console.log('Errore in una funzione citofono/cliente reduce');
        return {
          ...state,
          error: action.error.message
        };
      default:
        return state;
    }
  };

export default reducer;