import { Reducer } from 'redux';

import { AdminAction, AdminActionTypes as Type } from './Actions';

export interface AdminState {
  error: string | null;
}

export const initialState: AdminState = {
  error: null
};

export const reducer: Reducer<AdminState> =
  (state: AdminState = initialState, action: AdminAction) => {
    switch (action.type) {
      case Type.ADMIN_UPD_REQUEST:
        console.log('ADMIN_UPD_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.ADMIN_UPD_SUCCESS:
        console.log('ADMIN_UPD_SUCCESS reduce');
        return {
          ...state,
          error: null
        };
      case Type.ADMIN_UPD_FAILURE:
        console.log('ADMIN_UPD_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      case Type.ADMIN_CHPSW_REQUEST:
        console.log('ADMIN_CHPSW_REQUEST reduce');
        return {
          ...state,
          error: null
        };
      case Type.ADMIN_CHPSW_SUCCESS:
        console.log('ADMIN_CHPSW_SUCCESS reduce');
        return {
          ...state,
          error: null
        };
      case Type.ADMIN_CHPSW_FAILURE:
        console.log('ADMIN_CHPSW_FAILURE reduce: ', action.error);
        return {
          ...state,
          error: action.error.message
        };
      default:
        return state;
    }
  };

export default reducer;