import { combineReducers } from 'redux';
import { History } from 'history'
import { connectRouter, RouterState } from 'connected-react-router';
import authReducer, { AuthState } from './controller/login/Reducers';
import homReducer, { HomeState } from './controller/home/Reducers';
import apriPortaReducer, { ApriPortaState } from './controller/apriporta/Reducers';
import adminReducer, { AdminState } from './controller/admin/Reducers';
import citofoniReducer, { CitofoniState } from './controller/citofoni/Reducers';
import clientiReducer, { ClientiState } from './controller/clienti/Reducers';


export interface AppState {
   auth: AuthState;
   home: HomeState;
   admin: AdminState;
   citofoni: CitofoniState;
   clienti: ClientiState;
   apriPorta: ApriPortaState;
   router: RouterState<any>;
}

export const rootReducer = (history: History<any>) => combineReducers<AppState>({
   auth: authReducer,
   home: homReducer,
   admin: adminReducer,
   citofoni: citofoniReducer,
   clienti: clientiReducer,
   apriPorta: apriPortaReducer,
   router: connectRouter(history)
});

export default rootReducer;