import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { DatePicker, DefaultButton, Label, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, Text } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, ICliente, IClienteCitofono } from '../../api/Models';
import { clienteCitofonoHide, ClienteCitofonoHide, ClientiAction, clientiCitofonoUpdRequest, ClientiCitofonoUpdRequest } from '../controller/clienti/Actions';
import { DateHelper } from '../util/DateHelper';

interface IChangeAbilClientePanelProps {
  errorMsg: string | null;
  cliente: ICliente | null;
  citofono: ICitofono | null;
  citofonoCli: IClienteCitofono | null;
  close: () => ClienteCitofonoHide;
  aggiornaClienteCitofono: (clienteCitfono: IClienteCitofono) => ClientiCitofonoUpdRequest;

}

interface IChangeAbilClientePanelState {
  idAttivazione: string | null;
  dataInizio: number | null;
  dataFine: number | null;
  error: string | null;
}

class ChangeAbilClientePanel extends React.Component<IChangeAbilClientePanelProps, IChangeAbilClientePanelState> {
  constructor(props: IChangeAbilClientePanelProps) {
    super(props);
    this.state = {
      idAttivazione: this.props.citofonoCli?.idAttivazione || null,
      dataInizio: this.props.citofonoCli?.dataInizio || null,
      dataFine: this.props.citofonoCli?.dataFine || null,
      error: null
    };
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.onRenderFooterContent = this.onRenderFooterContent.bind(this);
    this.onSelectDataInizio = this.onSelectDataInizio.bind(this);
    this.onSelectDataFine = this.onSelectDataFine.bind(this);
  }

  static getDerivedStateFromProps(props: IChangeAbilClientePanelProps, state: IChangeAbilClientePanelState) {
    if (props.citofonoCli) {
      if (props.citofonoCli.idAttivazione !== state.idAttivazione) {
        return {
          idAttivazione: props.citofonoCli?.idAttivazione,
          dataInizio: props.citofonoCli?.dataInizio,
          dataFine: props.citofonoCli?.dataFine,
          error: null
        };
      }
    } else {
      if (state.idAttivazione) {
        return {
          idAttivazione: null,
          dataInizio: null,
          dataFine: null,
          error: null
        }
      }
    }

    return null;
  }
  private confirm() {
    // Valido i campi
    if (!this.state.dataInizio) {
      this.setState({ error: 'Campo data inizio obbligatorio.' });
      return;
    }
    let dIni = DateHelper.fixDataInizio(this.state.dataInizio);
    let dFine = DateHelper.fixDataDine(this.state.dataFine);
    if (dFine && dFine < dIni) {
      this.setState({ error: 'Il campo Data Fine deve essere successivo a Data Inizio.' });
      return;
    }
    // Devo normalizzare gli orari delle date
    // Data Inizio 00:00
    // Data FIne 23:59
    // Chiama la funzione di salva dati citofono
    if (this.props.citofonoCli) {
      let updCitCli = { ...this.props.citofonoCli };
      updCitCli.dataInizio = dIni;
      updCitCli.dataFine = dFine;
      this.props.aggiornaClienteCitofono(updCitCli);
    }
  }

  private close() {
    // Azzero i campi per evitare che rimangano valorizzati tra due invocazioni
    this.setState({ error: null, dataInizio: Date.now(), dataFine: null });
    // Chiamo il metodo di chiusura
    this.props.close();
  }
  private onRenderFooterContent() {
    return (
      <div>
        <PrimaryButton key="confimDglApprovaBtn" onClick={this.confirm} text="Salva" />
        <DefaultButton key="undoDglApprovaBtn" onClick={this.close} text="Chiudi" />
      </div>
    );
  }

  private onSelectDataInizio(date: Date | null | undefined): void {
    this.setState({ dataInizio: date ? Math.round(date.getTime() / 1000) : null });
  }

  private onSelectDataFine(date: Date | null | undefined): void {
    this.setState({ dataFine: date ? Math.round(date.getTime() / 1000) : null });
  }

  public render(): JSX.Element {

    return (
      <Panel
        isOpen={this.props.citofonoCli != null}
        hasCloseButton={false}
        type={PanelType.smallFixedFar}
        headerText='Modifica abilitazione citofono'
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {this.state.error && (
            <MessageBar
              messageBarType={MessageBarType.error}
            >
              {this.state.error}
            </MessageBar>
          )}
          <Text variant='small'>Modifica abilitazione al seguente citofono:</Text>
          <Text variant='xLarge'>{this.props.citofono?.denominazione}</Text>
          <Label>Per il cliente:</Label>
          <Text variant='xLarge'>{this.props.cliente?.nome}</Text>
          <Label>Periodo abilitazione citofono</Label>
          <Label>Data inizio*</Label>
          <DatePicker
            formatDate={(d) => d ? d.toLocaleString('it-IT', { dateStyle: 'short' }) : ''}
            value={(this.state.dataInizio) ? new Date(this.state.dataInizio * 1000) : undefined}
            onSelectDate={this.onSelectDataInizio}
            placeholder="Data inizio abilitazione"
          />
          <Label>Data fine</Label>
          <DatePicker
            formatDate={(d) => d ? d.toLocaleString('it-IT', { dateStyle: 'short' }) : ''}
            value={(this.state.dataFine) ? new Date(this.state.dataFine * 1000) : undefined}
            onSelectDate={this.onSelectDataFine}
            placeholder="Data fine abilitazione"
          />
        </Stack>
      </Panel>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.clienti?.error,
    citofonoCli: store.clienti.clienteCitofono
  };
};

const MapDispatchToProps = (dispatch: Dispatch<ClientiAction>) => ({
  close: () => dispatch(clienteCitofonoHide()),
  aggiornaClienteCitofono: (clienteCitofono: IClienteCitofono) => dispatch(clientiCitofonoUpdRequest(clienteCitofono))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(ChangeAbilClientePanel);