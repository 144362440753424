import { Reducer } from 'redux';

import { ApriPortaAction, ApriPortaActionTypes as Type } from './Actions';
import { IApriPortaResponse } from '../../../api/Models';

export interface ApriPortaState {
  valid: boolean;
  apriResponse: IApriPortaResponse | null;
  error: string | null;
}

export const initialState: ApriPortaState = {
  valid: false,
  apriResponse: null,
  error: null
};

export const reducer: Reducer<ApriPortaState, ApriPortaAction> =
  (state: ApriPortaState = initialState, action: ApriPortaAction) => {
    switch (action.type) {
      case Type.APRIPORTA_DATA_REQUEST:
        console.log('APRIPORTA_DATA_REQUEST reduce');
        return {
          ...state,
          valid: false,
          citofono: null,
          error: null
        };
      case Type.APRIPORTA_DATA_SUCCESS:
        console.log('APRIPORTA_DATA_SUCCESS reduce');
        return {
          ...state,
          valid: true,
          apriResponse: action.apriResponse,
          error: null
        };
      case Type.APRIPORTA_DATA_FAILURE:
        console.log('HOME_DATA_FAILURE reduce: ' + action.error);
        return {
          ...state,
          citofono: null,
          error: action.error.message
        };
      case Type.APRIPORTA_APRI:
        console.log('APRIPORTA_APRI reduce');
        return {
          ...state,
          error: null
        };
      case Type.APRIPORTA_APRI_OK:
        console.log('APRIPORTA_APRI_OK reduce');
        return {
          ...state,
          apriResponse: action.apriResponse,
          error: null
        };
      case Type.APRIPORTA_APRI_ERR:
        console.log('APRIPORTA_APRI_ERR reduce: ' + action.error);
        return {
          ...state,
          error: action.error.message
        };
      default:
        return state;
    }
  };

export default reducer;