import {
    call,
    put,
    take,
    takeEvery
} from 'redux-saga/effects';
import { push } from 'connected-react-router'
import {
    ActionTypes,
    // loginFailure,
    loginSuccess,
    LoginRequest,
    loginFailure,
    Logout,
    CheckLoginRequest
} from './Actions';
import { IHomeResponse, IUtente } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { homeDataSuccess } from '../home/Actions';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';

export function* loginRequestSaga(action: LoginRequest) {
    try {
        console.log('saga login...');
        let usr: IUtente = yield call([ApiClient.getInstance(), 'login'],action.userid, action.psw);
        let ret: IHomeResponse = yield call([ApiClient.getInstance(), 'getHomeData']);
        yield put(loginSuccess(usr));
        yield put(homeDataSuccess(ret));
        console.log('Login OK');
    } catch (error) {
        console.log('Login ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = {authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error)};
        }
        yield put(loginFailure(iErr));
    }
}


export function* checkLoginRequestSaga(action: CheckLoginRequest) {
    try {
        console.log('saga check login...');
        let usr: IUtente = yield call([ApiClient.getInstance(), 'getMe']);
        console.log('User: ', usr);
        let ret: IHomeResponse = yield call([ApiClient.getInstance(), 'getHomeData']);
        console.log('HomeData: ', ret);
        yield put(loginSuccess(usr));
        yield put(homeDataSuccess(ret));
        console.log('Check Login OK');
    } catch (error) {
        console.log('Check Login ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = {authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error)};
        }
        yield put(loginFailure(iErr));
    }
}

export function* logoutRequestSaga(action: Logout) {
    try {
        ApiClient.getInstance().clearApiToken();
        console.log('Logout OK');
    } catch (error) {
        console.log('Logout ERROR:', error);
    }

    yield put(push('/fes/login'));
}

export function* watchLoginRequest() {
    console.log('watchLoginRequest!');
    yield takeEvery(ActionTypes.LOGIN_REQUEST, loginRequestSaga);
}

export function* watchCheckLoginRequest() {
    console.log('watchCheckLoginRequest!');
    yield takeEvery(ActionTypes.CHKLOGIN_REQUEST, checkLoginRequestSaga);
}

export function* watchLoginSuccess() {
    yield take(ActionTypes.LOGIN_SUCCESS);
    console.log('HUGE SUCCESS!');
}

export function* watchLoginFailure() {
    yield take(ActionTypes.LOGIN_FAILURE);
    console.log('DAMMIT - SHIT FAILED YO');
}

export function* watchLogout() {
    yield takeEvery(ActionTypes.LOGOUT, logoutRequestSaga);
}
