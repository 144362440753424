import { IErrorResponse } from "../../../api/ErrorResponse";
import { ICliente, IClienteCitofono } from "../../../api/Models";

export enum ClientiActionTypes {
  CLIENTE_SHOW = 'CLIENTE_SHOW',
  CLIENTE_HIDE = 'CLIENTE_HIDE',
  CLIENTE_UPD_REQUEST = 'CLIENTE_UPD_REQUEST',
  CLIENTE_UPD_SUCCESS = 'CLIENTE_UPD_SUCCESS',
  CLIENTE_UPD_FAILURE = 'CLIENTE_UPD_FAILURE',
  CLICIT_SHOW = 'CLICIT_SHOW',
  CLICIT_HIDE = 'CLICIT_HIDE',
  CLICIT_UPD_REQUEST = 'CLICIT_UPD_REQUEST',
  CLICIT_UPD_SUCCESS = 'CLICIT_UPD_SUCCESS',
  CLICIT_UPD_FAILURE = 'CLICIT_UPD_FAILURE',
  CLICIT_SL_REQUEST = 'CLICIT_SL_REQUEST',
  CLICIT_SL_SUCCESS = 'CLICIT_SL_SUCCESS',
  CLICIT_SL_FAILURE = 'CLICIT_SL_FAILURE',
  ADDCLI_SHOW_REQUEST = 'ADDCLI_SHOW_REQUEST',
  ADDCLI_SHOW_SUCCESS = 'ADDCLI_SHOW_SUCCESS',
  ADDCLI_SHOW_FAILURE = 'ADDCLI_SHOW_FAILURE',
  ADDCLI_HIDE = 'ADDCLI_HIDE',
  ADDCLI_NEW_REQUEST = 'ADDCLI_NEW_REQUEST',
  ADDCLI_NEW_SUCCESS = 'ADDCLI_NEW_SUCCESS',
  ADDCLI_NEW_FAILURE = 'ADDCLI_NEW_FAILURE',
  CLICIT_DEL_REQUEST = 'CLICIT_DEL_REQUEST',
  CLICIT_DEL_SUCCESS = 'CLICIT_DEL_SUCCESS',
  CLICIT_DEL_FAILURE = 'CLICIT_DEL_FAILURE',
}

export interface ClienteShow {
  type: ClientiActionTypes.CLIENTE_SHOW;
  cliente: ICliente;
}

export interface ClienteHide {
  type: ClientiActionTypes.CLIENTE_HIDE;
}

export interface ClientiUpdRequest {
  type: ClientiActionTypes.CLIENTE_UPD_REQUEST;
  cliente: ICliente;
}

export interface ClientiUpdSuccess {
  type: ClientiActionTypes.CLIENTE_UPD_SUCCESS;
  cliente: ICliente;
}

export interface ClientiUpdFailure {
  type: ClientiActionTypes.CLIENTE_UPD_FAILURE;
  error: IErrorResponse;
}

export interface ClienteCitofonoShow {
  type: ClientiActionTypes.CLICIT_SHOW;
  clienteCitofono: IClienteCitofono;
}

export interface ClienteCitofonoHide {
  type: ClientiActionTypes.CLICIT_HIDE;
}

export interface ClientiCitofonoUpdRequest {
  type: ClientiActionTypes.CLICIT_UPD_REQUEST;
  clienteCitofono: IClienteCitofono;
}

export interface ClientiCitofonoUpdSuccess {
  type: ClientiActionTypes.CLICIT_UPD_SUCCESS;
  clienteCitofono: IClienteCitofono;
}

export interface ClientiCitofonoUpdFailure {
  type: ClientiActionTypes.CLICIT_UPD_FAILURE;
  error: IErrorResponse;
}

export interface ClientiCitofonoSLRequest {
  type: ClientiActionTypes.CLICIT_SL_REQUEST;
  idClienteCitofono: number;
}

export interface ClientiCitofonoSLSuccess {
  type: ClientiActionTypes.CLICIT_SL_SUCCESS;
  esito: string;
}

export interface ClientiCitofonoSLFailure {
  type: ClientiActionTypes.CLICIT_SL_FAILURE;
  error: IErrorResponse;
}

export interface ClientiAddShowRequest {
  type: ClientiActionTypes.ADDCLI_SHOW_REQUEST;
}

export interface ClientiAddShowSuccess {
  type: ClientiActionTypes.ADDCLI_SHOW_SUCCESS;
  clienti: ICliente[];
}

export interface ClientiAddShowFailure {
  type: ClientiActionTypes.ADDCLI_SHOW_FAILURE;
  error: IErrorResponse;
}

export interface ClientiAddHide {
  type: ClientiActionTypes.ADDCLI_HIDE;
}

export interface ClientiAddNewRequest {
  type: ClientiActionTypes.ADDCLI_NEW_REQUEST;
  cliente: ICliente | null;
  clienteCitofono: IClienteCitofono;
}

export interface ClientiAddNewSuccess {
  type: ClientiActionTypes.ADDCLI_NEW_SUCCESS;
  cliente: ICliente | null;
  clienteCitofono: IClienteCitofono | null;
}

export interface ClientiAddNewFailure {
  type: ClientiActionTypes.ADDCLI_NEW_FAILURE;
  error: IErrorResponse;
}

export interface ClientiCitofonoDelRequest {
  type: ClientiActionTypes.CLICIT_DEL_REQUEST;
  idClienteCitofono: number;
}

export interface ClientiCitofonoDelSuccess {
  type: ClientiActionTypes.CLICIT_DEL_SUCCESS;
  esito: string;
}

export interface ClientiCitofonoDelFailure {
  type: ClientiActionTypes.CLICIT_DEL_FAILURE;
  error: IErrorResponse;
}

export type ClientiAction =
  | ClienteShow
  | ClienteHide
  | ClientiUpdRequest
  | ClientiUpdSuccess
  | ClientiUpdFailure
  | ClienteCitofonoShow
  | ClienteCitofonoHide
  | ClientiCitofonoUpdRequest
  | ClientiCitofonoUpdSuccess
  | ClientiCitofonoUpdFailure
  | ClientiCitofonoSLRequest
  | ClientiCitofonoSLSuccess
  | ClientiCitofonoSLFailure
  | ClientiAddShowRequest
  | ClientiAddShowSuccess
  | ClientiAddShowFailure
  | ClientiAddHide
  | ClientiAddNewRequest
  | ClientiAddNewSuccess
  | ClientiAddNewFailure
  | ClientiCitofonoDelRequest
  | ClientiCitofonoDelSuccess
  | ClientiCitofonoDelFailure
  ;

// ---------------
// Action Creators
// ---------------

export const clienteShow = (cliente: ICliente): ClienteShow => ({
  type: ClientiActionTypes.CLIENTE_SHOW,
  cliente: cliente
});

export const clienteHide = (): ClienteHide => ({
  type: ClientiActionTypes.CLIENTE_HIDE
});

export const clientiUpdRequest = (cliente: ICliente): ClientiUpdRequest => ({
  type: ClientiActionTypes.CLIENTE_UPD_REQUEST,
  cliente: cliente
});

export const clientiUpdSuccess = (cliente: ICliente): ClientiUpdSuccess => ({
  type: ClientiActionTypes.CLIENTE_UPD_SUCCESS,
  cliente: cliente
});

export const clientiUpdFailure = (error: IErrorResponse): ClientiUpdFailure => ({
  type: ClientiActionTypes.CLIENTE_UPD_FAILURE,
  error: error,
});

export const clienteCitofonoShow = (clienteCitofono: IClienteCitofono): ClienteCitofonoShow => ({
  type: ClientiActionTypes.CLICIT_SHOW,
  clienteCitofono: clienteCitofono
});

export const clienteCitofonoHide = (): ClienteCitofonoHide => ({
  type: ClientiActionTypes.CLICIT_HIDE
});

export const clientiCitofonoUpdRequest = (clienteCitofono: IClienteCitofono): ClientiCitofonoUpdRequest => ({
  type: ClientiActionTypes.CLICIT_UPD_REQUEST,
  clienteCitofono: clienteCitofono
});

export const clientiCitofonoUpdSuccess = (clienteCitofono: IClienteCitofono): ClientiCitofonoUpdSuccess => ({
  type: ClientiActionTypes.CLICIT_UPD_SUCCESS,
  clienteCitofono: clienteCitofono
});

export const clientiCitofonoUpdFailure = (error: IErrorResponse): ClientiCitofonoUpdFailure => ({
  type: ClientiActionTypes.CLICIT_UPD_FAILURE,
  error: error,
});

export const clientiCitofonoSLRequest = (idClienteCitofono: number): ClientiCitofonoSLRequest => ({
  type: ClientiActionTypes.CLICIT_SL_REQUEST,
  idClienteCitofono: idClienteCitofono
});

export const clientiCitofonoSLSuccess = (esito: string): ClientiCitofonoSLSuccess => ({
  type: ClientiActionTypes.CLICIT_SL_SUCCESS,
  esito: esito
});

export const clientiCitofonoSLFailure = (error: IErrorResponse): ClientiCitofonoSLFailure => ({
  type: ClientiActionTypes.CLICIT_SL_FAILURE,
  error: error,
});

export const clientiAddShowRequest = (): ClientiAddShowRequest => ({
  type: ClientiActionTypes.ADDCLI_SHOW_REQUEST
});

export const clientiAddShowSuccess = (clienti: ICliente[]): ClientiAddShowSuccess => ({
  type: ClientiActionTypes.ADDCLI_SHOW_SUCCESS,
  clienti: clienti
});

export const clientiAddShowFailure = (error: IErrorResponse): ClientiAddShowFailure => ({
  type: ClientiActionTypes.ADDCLI_SHOW_FAILURE,
  error: error,
});

export const clientiAddHide = (): ClientiAddHide => ({
  type: ClientiActionTypes.ADDCLI_HIDE
});


export const clientiAddNewRequest = (cliente: ICliente | null, clienteCitofono: IClienteCitofono): ClientiAddNewRequest => ({
  type: ClientiActionTypes.ADDCLI_NEW_REQUEST,
  cliente: cliente,
  clienteCitofono: clienteCitofono
});

export const clientiAddSNewSuccess = (cliente: ICliente | null, clienteCitofono: IClienteCitofono | null): ClientiAddNewSuccess => ({
  type: ClientiActionTypes.ADDCLI_NEW_SUCCESS,
  cliente: cliente,
  clienteCitofono: clienteCitofono
});

export const clientiAddNewFailure = (error: IErrorResponse): ClientiAddNewFailure => ({
  type: ClientiActionTypes.ADDCLI_NEW_FAILURE,
  error: error,
});

export const clientiCitofonoDelRequest = (idClienteCitofono: number): ClientiCitofonoDelRequest => ({
  type: ClientiActionTypes.CLICIT_DEL_REQUEST,
  idClienteCitofono: idClienteCitofono
});

export const clientiCitofonoDelSuccess = (esito: string): ClientiCitofonoDelSuccess => ({
  type: ClientiActionTypes.CLICIT_DEL_SUCCESS,
  esito: esito
});

export const clientiCitofonoDelFailure = (error: IErrorResponse): ClientiCitofonoDelFailure => ({
  type: ClientiActionTypes.CLICIT_DEL_FAILURE,
  error: error,
});