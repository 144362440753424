import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { Callout, IconButton, mergeStyles, Stack, Text } from '@fluentui/react';
import { HomeAction, homeDataRequest, HomeDataRequest } from '../controller/home/Actions';

import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import UserAdminElem from '../component/UserAdminElem';
import { IUtente } from '../../api/Models';

interface IHeaderProps {
  admin: IUtente | null;
  title: string;
  refreshHomeData: () => HomeDataRequest;
}

interface IHeaderState {
  showAdminCallout: boolean;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      showAdminCallout: false
    };

  }

  public render(): JSX.Element {
    const buttonId = 'btnAdmin1';
    const iconClass = mergeStyles({
      fontSize: 30,
      height: 30,
      width: 30,
      margin: '5px 5px',
      color: 'black'
    });
    return (
      <Stack horizontal={true} style={{backgroundColor: 'lightgray', padding: '10px'}} verticalAlign='center'>
        <Text variant='xLargePlus' style={{flexGrow: 1}}>{this.props.title}</Text>
        {this.props.admin &&
        <IconButton iconProps={{ iconName: 'Refresh', title: 'Aggiorna' }} className={iconClass} onClick={() => { this.props.refreshHomeData() }} />
        }
        {this.props.admin &&
        <IconButton id={buttonId} iconProps={{ iconName: 'AdminALogo32', title: 'Amministratore' }} className={iconClass} onClick={() => {this.setState({showAdminCallout: true})}} />
        }
        {(this.props.admin && this.state.showAdminCallout) &&
          <Callout
            role="alertdialog"
            styles={{root: {width: '300px'}}}
            gapSpace={0}
            target={`#${buttonId}`}
            onDismiss={() => {this.setState({showAdminCallout: false})}}
            setInitialFocus
          >
            <UserAdminElem admin={this.props.admin}/>
          </Callout>
        }
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    admin: store.auth.user
  };
};

const MapDispatchToProps = (dispatch: Dispatch<HomeAction>) => ({
  refreshHomeData: () => dispatch(homeDataRequest())
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(Header);