import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, IconButton, IStackTokens, mergeStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, ICliente, IClienteCitofono } from '../../api/Models';
import ChangeAbilClientePanel from './ChangeAbilClientePanel';
import ClientePanel from './ClientePanel';
import { ClienteCitofonoShow, clienteCitofonoShow, clienteShow, ClienteShow, ClientiAction, clientiCitofonoDelRequest, ClientiCitofonoDelRequest, clientiCitofonoSLRequest, ClientiCitofonoSLRequest } from '../controller/clienti/Actions';
import { DateHelper } from '../util/DateHelper';

interface IClienteElemProps {
  errorMsg: string | null;
  citofoni?: ICitofono[];
  cliente: ICliente;
  showCliente: (cliente: ICliente) => ClienteShow;
  showClienteCitofono: (clienteCitfono: IClienteCitofono) => ClienteCitofonoShow;
  sendLink: (idClienteCitofono: number) => ClientiCitofonoSLRequest;
  delClienteCitofono: (idClienteCitofono: number) => ClientiCitofonoDelRequest;
}

interface IClienteElemState {
  dlgInviaLinkConfirm: boolean;
  currCitCli: IClienteCitofono | null;
  currCitofono: ICitofono | null;
  dlgDelConfirm: boolean;
}

class ClienteElem extends React.Component<IClienteElemProps, IClienteElemState> {
  constructor(props: IClienteElemProps) {
    super(props);
    this.state = {
      dlgInviaLinkConfirm: false,
      currCitCli: null,
      currCitofono: null,
      dlgDelConfirm: false
    };

    this.closeDlg = this.closeDlg.bind(this);
    this.doSendLink = this.doSendLink.bind(this);
    this.closeDelDlg = this.closeDelDlg.bind(this);
    this.doElimina = this.doElimina.bind(this);
  }

  private getCitofono(idCitofono: number): ICitofono | null {
    if (idCitofono && this.props.citofoni) {
      return this.props.citofoni.find((cit) => cit.idCitofono === idCitofono) || null;
    }

    return null;
  }

  private getCitofonoDes(idCitofono: number): string {
    let ret = '' + idCitofono;
    let cit = this.getCitofono(idCitofono);
    if (cit && cit.denominazione) {
      ret = cit.denominazione;
    }

    return ret;
  }

  private isAttivo(citCli: IClienteCitofono): boolean {
    if (citCli && citCli.idAttivazione && citCli.idAttivazione.trim().length > 0) {
      // Devo verificare che sia nel periodo giusto
      let adesso = Date.now();
      if (citCli.dataInizio && adesso >= citCli.dataInizio && (citCli.dataFine == null || adesso <= citCli.dataFine)) {
        return true;
      }
    }

    return true;
  }

  private vaiAlLink(citCli: IClienteCitofono) {
    if (citCli && citCli.idAttivazione) {
      let url = '/apriporta?' + encodeURIComponent(citCli.idAttivazione);
      let newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }

  private canSendLink() {
    if (this.props.cliente) {
      if (this.props.cliente.email && this.props.cliente.email.trim().length > 0) {
        return true;
      }
      if (this.props.cliente.telefono && this.props.cliente.telefono.trim().length > 0) {
        return true;
      }
    }
    return false;
  }

  private closeDlg() {
    this.setState({ currCitofono: null, currCitCli: null, dlgInviaLinkConfirm: false });
  }

  private doSendLink() {
    if (this.state.currCitCli) {
      this.props.sendLink(this.state.currCitCli.idClienteCitofono);
    }
    this.closeDlg();
  }

  private closeDelDlg() {
    this.setState({ currCitofono: null, currCitCli: null, dlgInviaLinkConfirm: false, dlgDelConfirm: false });
  }

  private doElimina() {
    if (this.state.currCitCli) {
      this.props.delClienteCitofono(this.state.currCitCli.idClienteCitofono);
    }
    this.closeDelDlg();
  }

  private showClienteCitofono(currCitCli: IClienteCitofono, currCitofono: ICitofono | null) {
    this.setState({ currCitCli: currCitCli, currCitofono: currCitofono, dlgInviaLinkConfirm: false });
    this.props.showClienteCitofono(currCitCli);
  }

  private showSendLink(currCitCli: IClienteCitofono, currCitofono: ICitofono | null) {
    this.setState({ currCitCli: currCitCli, currCitofono: currCitofono, dlgInviaLinkConfirm: true, dlgDelConfirm: false });
  }

  private deleteClienteCitofono(citCli: IClienteCitofono, currCitofono: ICitofono | null) {
    // Chiedo se vuole cancellare e se ok, cancello
    this.setState({ currCitCli: citCli, currCitofono: currCitofono, dlgInviaLinkConfirm: false, dlgDelConfirm: true });
  }

  public render(): JSX.Element {
    const iconClass = mergeStyles({
      fontSize: 14,
      height: 14,
      width: 14,
      margin: '0 5px 0 0',
    });
    const customSpacingStackTokens: IStackTokens = {
      childrenGap: '10px',
      padding: 'm 5px'
    };
    return (
      <Stack styles={{ root: { border: '1px solid lightgray' } }}>
        <ActionButton iconProps={{ iconName: 'Contact' }} onClick={() => this.props.showCliente(this.props.cliente)}>Cliente</ActionButton>
        <Stack styles={{ root: { margin: '0px 16px 0px 16px' } }} tokens={customSpacingStackTokens} >
          <Text variant={'large'}>{this.props.cliente.nome}</Text>
          <Stack horizontal={true} styles={{ root: { marginTop: '15px !important' } }} verticalAlign='baseline'>
            <FontIcon iconName='Mail' className={iconClass} /><Text variant={'medium'}>{this.props.cliente.email}</Text>
          </Stack>
          <Stack horizontal={true} verticalAlign='baseline'>
            <FontIcon iconName='Phone' className={iconClass} /><Text variant={'medium'}>{this.props.cliente.telefono}</Text>
          </Stack>
        </Stack>
        <Stack styles={{ root: { margin: '0px 16px 0px 16px' } }}>
          <Text variant={'mediumPlus'}>Citofoni abilitati:</Text>
          {this.props.cliente.citofoni && this.props.cliente.citofoni.filter((citCli) => this.isAttivo(citCli)).map((citCli, idx) => {
            let currCit = this.getCitofono(citCli.idCitofono);
            return (
              <Stack key={idx} horizontal={false}>
                <Stack horizontal={true}>
                  <ActionButton iconProps={{ iconName: 'CellPhone' }} onClick={() => this.showClienteCitofono(citCli, currCit)}>{this.getCitofonoDes(citCli.idCitofono)}</ActionButton>
                  <IconButton iconProps={{ iconName: 'OpenInNewTab', title: 'Vai al link apri porta' }} onClick={() => this.vaiAlLink(citCli)} />
                  {this.canSendLink() &&
                    <IconButton iconProps={{ iconName: 'CommentNext', title: 'Invia il link al cliente' }} onClick={() => this.showSendLink(citCli, currCit)} />
                  }
                  <IconButton iconProps={{ iconName: 'Delete', title: 'Cancella' }} onClick={() => this.deleteClienteCitofono(citCli, currCit)} />
                </Stack>
                <Stack horizontal={true} styles={{root: {paddingLeft: '30px'}}}>
                {citCli.dataInizio &&
                <Text variant={'xSmall'}>dal {DateHelper.getDateString(citCli.dataInizio)}</Text>
                }
                {citCli.dataFine &&
                <Text variant={'xSmall'} styles={{root: {paddingLeft: '5px'}}}>al { DateHelper.getDateString(citCli.dataFine)}</Text>
                }

                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <ClientePanel />
        <ChangeAbilClientePanel cliente={this.props.cliente} citofono={this.state.currCitofono} />
        <Dialog
          styles={{ main: { maxWidth: 450 } }}
          hidden={!this.state.dlgInviaLinkConfirm}
          onDismiss={this.closeDlg}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Invia link',
            closeButtonAriaLabel: 'Annulla',
            subText: 'Vuoi inviare al cliente il link per aprire la porta?',
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.doSendLink} text="Invia" />
            <DefaultButton onClick={this.closeDlg} text="Annulla" />
          </DialogFooter>
        </Dialog>
        <Dialog
          styles={{ main: { maxWidth: 450 } }}
          hidden={!this.state.dlgDelConfirm}
          onDismiss={this.closeDelDlg}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Elimina abilitazione',
            closeButtonAriaLabel: 'Annulla',
            subText: 'Confermando il cliente non potrà più aprire la porta di questo citofono?',
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.doElimina} text="Conferma" />
            <DefaultButton onClick={this.closeDelDlg} text="Annulla" />
          </DialogFooter>
        </Dialog>

      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<ClientiAction>) => ({
  showCliente: (cliente: ICliente) => dispatch(clienteShow(cliente)),
  showClienteCitofono: (clienteCitfono: IClienteCitofono) => dispatch(clienteCitofonoShow(clienteCitfono)),
  sendLink: (idClienteCitofono: number) => dispatch(clientiCitofonoSLRequest(idClienteCitofono)),
  delClienteCitofono: (idClienteCitofono: number) => dispatch(clientiCitofonoDelRequest(idClienteCitofono))
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(ClienteElem);