import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { Action } from '../controller/login/Actions';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';

interface ICitofoniViewProps {
  errorMsg: string | null;
}

interface ICitofoniViewState {
}

class CitofoniView extends React.Component<ICitofoniViewProps, ICitofoniViewState> {
  constructor(props: ICitofoniViewProps) {
    super(props);
    this.state = {
    };

  }

  public render(): JSX.Element {

    return (
      <Stack horizontal={false}>
        {(this.props.errorMsg && this.props.errorMsg.trim().length > 0) &&
        <MessageBar
          messageBarType={MessageBarType.error}
        >
          {this.props.errorMsg}
        </MessageBar>
        }
        <p>Citofoni.</p>
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
    errorMsg: store.auth?.error
  };
};

const MapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(CitofoniView);