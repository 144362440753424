import { Reducer } from 'redux';

import { Action as AuthAction, ActionTypes as Type } from './Actions';
import { IUtente } from '../../../api/Models';

export interface AuthState {
  isLoggingIn: boolean;
  user: IUtente | null;
  error: string | null;
  uuid: string | null;
}

export const initialState: AuthState = {
  isLoggingIn: false,
  user: null,
  error: null,
  uuid: null
};

export const reducer: Reducer<AuthState> =
  (state: AuthState = initialState, action: AuthAction) => {
    switch (action.type) {
      case Type.LOGIN_REQUEST:
        console.log('LOGIN_REQUEST reduce');
        return {
          ...state,
          user: null,
          error: null,
          isLoggingIn: true,
        };
      case Type.CHKLOGIN_REQUEST:
        console.log('CHKLOGIN_REQUEST reduce');
        return {
          ...state,
          user: null,
          error: null,
          isLoggingIn: true,
        };
      case Type.LOGIN_SUCCESS:
        console.log('LOGIN_SUCCESS reduce');
        return {
          ...state,
          user: action.user,
          error: null,
          isLoggingIn: false,
        };
      case Type.LOGIN_FAILURE:
        console.log('LOGIN_FAILURE reduce: ', action.error);
        return {
          ...state,
          isLoggingIn: false,
          user: null,
          error: action.error.message,
        };
      case Type.LOGOUT:
        console.log('LOGOUT reduce');
        return initialState;
      case Type.ADMIN_UPD:
        console.log('ADMIN_UPD reduce');
        return {
          ...state,
          user: action.user,
          error: null,
        };
      default:
        return state;
    }
  };

export default reducer;