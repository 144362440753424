import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    AdminActionTypes,
    AdminUpdRequest,
    adminUpdSuccess,
    adminUpdFailure,
    AdminChPswRequest,
    adminChPswSuccess,
    adminChPswFailure
} from './Actions';
import { IUtente } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';
import { adminUpd, loginFailure } from '../login/Actions';

export function* adminUpdRequestSaga(action: AdminUpdRequest) {
    try {
        console.log('saga aggiorna utente...');
        let ret: IUtente = yield call([ApiClient.getInstance(), 'aggiornaUtente'], action.user);
        yield put(adminUpdSuccess(ret));
        yield put(adminUpd(ret));
        console.log('Aggiorna admin OK');
    } catch (error) {
        console.log('Aggiorna admin ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(adminUpdFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchAdminUpdRequest() {
    console.log('watchAdminUpdRequest!');
    yield takeEvery(AdminActionTypes.ADMIN_UPD_REQUEST, adminUpdRequestSaga);
}

export function* adminChPswRequestSaga(action: AdminChPswRequest) {
    try {
        console.log('saga cambia password utente...');
        yield call([ApiClient.getInstance(), 'cambiaPsw'], action.oldPsw, action.newPsw);
        yield put(adminChPswSuccess());
        console.log('Cambio psw OK');
    } catch (error) {
        console.log('Cambio psw ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(adminChPswFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchAdminChPswRequest() {
    console.log('watchAdminChPswRequest!');
    yield takeEvery(AdminActionTypes.ADMIN_CHPSW_REQUEST, adminChPswRequestSaga);
}
