import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    CitofoniActionTypes,
    CitofoniUpdRequest,
    citofoniUpdSuccess,
    citofoniUpdFailure,
    CitofoniApriRequest,
    citofoniApriSuccess,
    citofoniApriFailure
} from './Actions';
import { ICitofono } from '../../../api/Models';
import { ApiClient } from '../../../api/ApiClient';
import { ErrorResponse, IErrorResponse } from '../../../api/ErrorResponse';
import { loginFailure } from '../login/Actions';
import { homeDataRequest } from '../home/Actions';

export function* citofoniUpdRequestSaga(action: CitofoniUpdRequest) {
    try {
        console.log('saga aggiorna citofono...');
        let ret: ICitofono = yield call([ApiClient.getInstance(), 'aggiornaCitofono'], action.citofono);
        yield put(citofoniUpdSuccess(ret));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Aggiorna citofono OK');
    } catch (error) {
        console.log('Aggiorna citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(citofoniUpdFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchCitofoniUpdRequest() {
    console.log('watchCitofoniUpdRequest!');
    yield takeEvery(CitofoniActionTypes.CIT_UPD_REQUEST, citofoniUpdRequestSaga);
}

export function* citofoniApriRequestSaga(action: CitofoniApriRequest) {
    try {
        console.log('saga apri citofono...');
        let ret: ICitofono = yield call([ApiClient.getInstance(), 'apriPorta'], action.idCitofono);
        yield put(citofoniApriSuccess(ret));
        // Reload dati home per visualizzare i dati aggiornati
        yield put(homeDataRequest());
        console.log('Apri citofono OK');
    } catch (error) {
        console.log('Apri citofono ERROR:', error);
        let errResp = error as ErrorResponse;
        let iErr: IErrorResponse;
        if (errResp) {
            iErr = errResp.getIErrorResponse();
        } else {
            iErr = { authInteractive: false, code: 500, statusText: 'Errore', message: 'Errore: ' + JSON.stringify(error) };
        }
        yield put(citofoniApriFailure(iErr));
        if (iErr.code === 401) {
            yield put(loginFailure(iErr));
        }
    }
}

export function* watchCitofoniApriRequest() {
    console.log('watchCitofoniApriRequest!');
    yield takeEvery(CitofoniActionTypes.CIT_APRI_REQUEST, citofoniApriRequestSaga);
}
