import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootSaga from './RootSagas'; 
import rootReducer from './RootReducer';

const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory();

export const store = configureStore({
    reducer: rootReducer(history),
    middleware: [...getDefaultMiddleware(),  sagaMiddleware, routerMiddleware(history)]
  });

  sagaMiddleware.run(rootSaga);