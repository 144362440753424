
export interface IUtente {
  idUtente: number;
  userid: string;
  nome: string;
  email?: string;
  telefono?: string;
  token?: string;
}

export interface IClienteCitofono {
  idClienteCitofono: number;
  idCliente: number;
  idCitofono: number;
  dataInizio: number;
  dataFine?: number;
  idAttivazione: string;
}

export interface ICliente {
  idCliente: number;
  nome: string;
  email: string;
  telefono: string;
  info: string;
  citofoni: IClienteCitofono[];
}
export enum StatoCitofono {
  Sconosciuto = 1,
  Connesso = 2,
  Disconnesso = 3
}

export interface ICitofono {
  idCitofono: number;
  denominazione: string;
  indirizzo: string;
  localita: string;
  cap: number;
  idIot: string;
  pubKeyIot: string;
  stato: StatoCitofono;
  lastTS: number;
}

export enum TipoEvento {
  Campanello = 1,
  ApriPorta = 2,
  PortaAperta = 3,
  NotificaLink = 4,
  NotificaCampanello = 5,
  NotificaApriPorta = 6,
  NotificaPortaAperta = 7,
  CitofonoConnesso = 8,
  CitofonoDisconnesso = 9,
  Verifica = 10,
  Conferma = 11
}

export enum TipoNotifica {
  SMS = 1,
  Mail = 2
}

export interface ILogEvento {
  idEvento: number;
  idTipoEvento: TipoEvento;
  idCitofono: number;
  idCliente: number;
  idUtente: number;
  ts: number;
  idTipoNotifica: TipoNotifica;
  descrizione: string;
  nomeCliente?: string;
  nomeUtente?: string;
}

export interface IHomeResponse {
  clienti: ICliente[];
  citofoni: ICitofono[];
  lastLogEvents: ILogEvento[];
}

export interface IApriPortaResponse {
  nomeCliente?: string;
  denominazione?: string;
  indirizzo?: string;
  localita?: string;
  cap?: number;
  dataInizio?: number;
  dataFine?: number;
}

