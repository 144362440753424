import { IErrorResponse } from "../../../api/ErrorResponse";
import { IUtente } from "../../../api/Models";

export enum AdminActionTypes {
  ADMIN_UPD_REQUEST = 'ADMIN_UPD_REQUEST',
  ADMIN_UPD_SUCCESS = 'ADMIN_UPD_SUCCESS',
  ADMIN_UPD_FAILURE = 'ADMIN_UPD_FAILURE',
  ADMIN_CHPSW_REQUEST = 'ADMIN_CHPSW_REQUEST',
  ADMIN_CHPSW_SUCCESS = 'ADMIN_CHPSW_SUCCESS',
  ADMIN_CHPSW_FAILURE = 'ADMIN_CHPSW_FAILURE',
}

export interface AdminUpdRequest {
  type: AdminActionTypes.ADMIN_UPD_REQUEST;
  user: IUtente;
}

export interface AdminUpdSuccess {
  type: AdminActionTypes.ADMIN_UPD_SUCCESS;
  user: IUtente;
}

export interface AdminUpdFailure {
  type: AdminActionTypes.ADMIN_UPD_FAILURE;
  error: IErrorResponse;
}

export interface AdminChPswRequest {
  type: AdminActionTypes.ADMIN_CHPSW_REQUEST;
  oldPsw: string;
  newPsw: string;
}

export interface AdminChPswSuccess {
  type: AdminActionTypes.ADMIN_CHPSW_SUCCESS;
}

export interface AdminChPswFailure {
  type: AdminActionTypes.ADMIN_CHPSW_FAILURE;
  error: IErrorResponse;
}

export type AdminAction =
  | AdminUpdRequest
  | AdminUpdSuccess
  | AdminUpdFailure
  | AdminChPswRequest
  | AdminChPswSuccess
  | AdminChPswFailure
  ;

// ---------------
// Action Creators
// ---------------

export const adminUpdRequest = (usr: IUtente): AdminUpdRequest => ({
  type: AdminActionTypes.ADMIN_UPD_REQUEST,
  user: usr
});

export const adminUpdSuccess = (usr: IUtente): AdminUpdSuccess => ({
  type: AdminActionTypes.ADMIN_UPD_SUCCESS,
  user: usr
});

export const adminUpdFailure = (error: IErrorResponse): AdminUpdFailure => ({
  type: AdminActionTypes.ADMIN_UPD_FAILURE,
  error: error,
});

export const adminChPswRequest = (oldPsw: string, newPsw: string): AdminChPswRequest => ({
  type: AdminActionTypes.ADMIN_CHPSW_REQUEST,
  oldPsw: oldPsw,
  newPsw: newPsw
});

export const adminChPswSuccess = (): AdminChPswSuccess => ({
  type: AdminActionTypes.ADMIN_CHPSW_SUCCESS
});

export const adminChPswFailure = (error: IErrorResponse): AdminChPswFailure => ({
  type: AdminActionTypes.ADMIN_CHPSW_FAILURE,
  error: error,
});
