import React from 'react';
import { Dispatch } from "redux";
import '../../App.css';
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, IStackTokens, mergeStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { ICitofono, StatoCitofono } from '../../api/Models';
import CitofonoPanel from './CitofonoPanel';
import AddClientePanel from './AddClientePanel';
import { CitofoniAction, citofoniApriRequest, CitofoniApriRequest, citofonoShow, CitofonoShow } from '../controller/citofoni/Actions';
import { ClientiAction, clientiAddShowRequest, ClientiAddShowRequest } from '../controller/clienti/Actions';
import { ApiClient } from '../../api/ApiClient';
import { HomeAction, homeDataRequest, HomeDataRequest } from '../controller/home/Actions';
import { IErrorResponse } from '../../api/ErrorResponse';

interface ICitofonoElemProps {
  citofono: ICitofono;
  showCitofono: (citofono: ICitofono) => CitofonoShow;
  showAddCli: () => ClientiAddShowRequest;
  apriPorta: (idCitofono: number) => CitofoniApriRequest;
  refreshHomeData: () => HomeDataRequest;
}

interface ICitofonoElemState {
  dlgApriConfirm: boolean;
}

class CitofonoElem extends React.Component<ICitofonoElemProps, ICitofonoElemState> {
  constructor(props: ICitofonoElemProps) {
    super(props);
    this.state = {
      dlgApriConfirm: false
    };

    this.apriPorta = this.apriPorta.bind(this);
    this.doApriPorta = this.doApriPorta.bind(this);
    this.closeDlg = this.closeDlg.bind(this);
  }

  private getStatusIcon(): string {
    let ret = 'Unknown';

    switch (this.props.citofono.stato) {
      case StatoCitofono.Connesso:
        ret = 'PlugConnected';
        break;
      case StatoCitofono.Disconnesso:
        ret = 'PlugDisconnected';
        break;
    }

    return ret;
  }

  private getStatusLabel(): string {
    let ret = 'Sconosciuto';

    switch (this.props.citofono.stato) {
      case StatoCitofono.Connesso:
        ret = 'Connesso';
        break;
      case StatoCitofono.Disconnesso:
        ret = 'Disconnesso';
        break;
    }

    return ret;
  }

  private getLastTS() {
    let ret = '';

    if (this.props.citofono.lastTS) {
      let d = new Date(this.props.citofono.lastTS * 1000);
      ret = d.toLocaleString('it-IT');
    }

    return ret;
  }

  private apriPorta() {
    this.setState({ dlgApriConfirm: true });
  }

  private doApriPorta() {
    this.setState({ dlgApriConfirm: false });
    this.props.apriPorta(this.props.citofono.idCitofono);
  }

  private closeDlg() {
    this.setState({ dlgApriConfirm: false });
  }

  private async verifica() {
    try {
      await ApiClient.getInstance().verifica(this.props.citofono.idCitofono);
      console.log('Verifica inviata.');
      this.props.refreshHomeData();
    } catch (err) {
      console.log('Richiesta verifica connessione', err);
      let iErr = err as IErrorResponse;
      if (iErr && iErr.code === 401) {
        this.props.refreshHomeData();
      } else {
        alert('Verifica fallita. Errore: ' + JSON.stringify(err));
      }
    }
  }

  public render(): JSX.Element {
    const iconClass = mergeStyles({
      fontSize: 14,
      height: 14,
      width: 14,
      margin: '0 5px 0 0',
    });
    const customSpacingStackTokens: IStackTokens = {
      childrenGap: '10px',
      padding: 'm 5px'
    };
    return (
      <Stack styles={{ root: { border: '1px solid lightgray' } }}>
        <ActionButton iconProps={{ iconName: 'CellPhone' }} onClick={() => this.props.showCitofono({ ...this.props.citofono })} >Citofono</ActionButton>
        <Stack styles={{ root: { margin: '0px 16px 0px 16px' } }} tokens={customSpacingStackTokens} >
          <Text variant={'large'}>{this.props.citofono.denominazione}</Text>
          <Text variant={'mediumPlus'}>{this.props.citofono.indirizzo}</Text>
          <Text variant={'medium'}>{this.props.citofono.cap + ' - ' + this.props.citofono.localita}</Text>
          <Stack horizontal={true} styles={{ root: { marginTop: '15px !important' } }} verticalAlign='baseline'>
            <FontIcon iconName={this.getStatusIcon()} className={iconClass} /><Text variant={'medium'}>Stato: {this.getStatusLabel()}</Text>
          </Stack>
          <Stack horizontal={true} verticalAlign='baseline'>
            <FontIcon iconName={'Clock'} className={iconClass} /><Text variant={'medium'}>{this.getLastTS()}</Text>
          </Stack>
        </Stack>
        <ActionButton iconProps={{ iconName: 'Plug' }} onClick={() => this.verifica()}>Verifica connessione</ActionButton>
        <ActionButton iconProps={{ iconName: 'Unlock' }} onClick={() => this.apriPorta()}>Apri la porta</ActionButton>
        <ActionButton iconProps={{ iconName: 'AddFriend' }} onClick={() => this.props.showAddCli()}>Aggiungi cliente</ActionButton>
        <Dialog
          styles={{ main: { maxWidth: 450 } }}
          hidden={!this.state.dlgApriConfirm}
          onDismiss={this.closeDlg}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Apri porta',
            closeButtonAriaLabel: 'Annulla',
            subText: 'Vuoi aprire la porta di: ' + this.props.citofono.denominazione + '?',
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.doApriPorta} text="Apri" />
            <DefaultButton onClick={this.closeDlg} text="Annulla" />
          </DialogFooter>
        </Dialog>
        <CitofonoPanel />
        <AddClientePanel citofono={this.props.citofono} />
      </Stack>
    );
  }
}

const MapStateToProps = (store: AppState) => {
  return {
  };
};

const MapDispatchToProps = (dispatch: Dispatch<HomeAction | CitofoniAction | ClientiAction>) => ({
  showCitofono: (citofono: ICitofono) => dispatch(citofonoShow(citofono)),
  showAddCli: () => dispatch(clientiAddShowRequest()),
  apriPorta: (idCitofono: number) => dispatch(citofoniApriRequest(idCitofono)),
  refreshHomeData: () => dispatch(homeDataRequest())
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(CitofonoElem);